import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Collapse, Row, Col, Typography, Button, List, DatePicker, Card, Radio } from 'antd'
import Telemarketing from './Telemarketing';
import LiveLead from './LiveLead';
import Mailer from './Mailer';
import SocialMedia from './SocialMedia';
import DataLead from './DataLead';
import AgedLead from './AgedLead';
import moment from 'moment'
import CostAcquisition from './CostAcquisition'
import { mockComponent } from 'react-dom/test-utils';
import { DownOutlined, UpOutlined, RightOutlined } from '@ant-design/icons';
const { Title, Text } = Typography;
const { Panel } = Collapse;

class Marketing extends Component {
    constructor(props) {
      super(props);
        this.state = {
            premium: 1,
            premiumPerItem: 1,
            itemsRequired: 1,
            itemsPerHousehold: 1,
            householdsNeeded: 1,
            closingRatio: 1,
            totalMonthly: 1,
            loaded: false,

            autoGoals: 1,
            homeGoals: 1,
            specialtyLines: 1,
            totalSales: 1,

            telemarketingTotal: 0,
            telemarketingCount: 0,
            telemarketingQuotes: 0,
            telemarketingMarketing: 0,
            telemarketingCostPerItem: 0,

            socialMediaTotal: 0,
            socialMediaCount: 0,
            socialMediaQuotes: 0,
            socialMediaMarketing: 0,
            socialMediaCostPerItem: 0,

            mailerTotal: 0,
            mailerCount: 0,
            mailerQuotes: 0,
            mailerMarketing: 0,
            mailerCostPerItem: 0,

            liveLeadTotal: 0,
            liveLeadCount: 0,
            liveLeadQuotes: 0,
            liveLeadMarketing: 0,
            liveLeadCostPerItem: 0,

            dataLeadTotal: 0,
            dataLeadCount: 0,
            dataLeadQuotes: 0,
            dataLeadMarketing: 0,
            dataLeadCostPerItem: 0,

            agedLeadTotal: 0,
            agedLeadCount: 0,
            agedLeadQuotes: 0,
            agedLeadMarketing: 0,
            agedLeadCostPerItem: 0,
            monthDts: moment().format("MMM YYYY"),
            selectedDate: new Date(),
            showTelemarketer: false,
            showLiveLead: false,
            showMailer: false,
            showDataLead: false,
            showSocialMedia: false,
            showAgedLead: false,
            showReport: 'Marketing',
            dateFrom: moment().startOf('month').format("MM/DD/YYYY"),
            dateTo: moment().endOf('month').format("MM/DD/YYYY")
        };
        this.onTelemarketingChange = this.onTelemarketingChange.bind(this);
        this.onSocialMediaChange = this.onSocialMediaChange.bind(this);
        this.onMailerChange = this.onMailerChange.bind(this);
        this.onLiveLeadChange = this.onLiveLeadChange.bind(this);
        this.onDataLeadChange = this.onDataLeadChange.bind(this);
        this.onAgedLeadChange = this.onAgedLeadChange.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
    }
    componentDidMount(){
        const userId = localStorage.getItem("userId");
        const agencyId = localStorage.getItem("agencyId");
        if(agencyId == null){
            this.props.history.push('/');
        }
        this.props.getMarketing(agencyId, moment().format("MMM YYYY"));
        const dateTo = moment().endOf('month').format("MM/DD/YYYY");
        const dateFrom = moment().startOf('month').format("MM/DD/YYYY");
        this.props.getVendorSummary(agencyId, dateFrom, dateTo, '');
        this.setState({loaded: true});
        this.props.getMarketingSummary(agencyId, moment().format("MMM YYYY"));
        this.props.getActualMarketingSummary(agencyId, dateFrom, dateTo);
        this.props.getTelemarketing(agencyId, moment().format("MMM YYYY"));
        this.props.getLiveLead(agencyId, moment().format("MMM YYYY"));
        this.props.getMailer(agencyId, moment().format("MMM YYYY"));
        this.props.getDataLead(agencyId, moment().format("MMM YYYY"));
        this.props.getSocialMedia(agencyId, moment().format("MMM YYYY"));
        this.props.getAgedLead(agencyId, moment().format("MMM YYYY"));
    }
    componentDidUpdate(prevProps){
        if(this.props.marketing != prevProps.marketing){
            this.setState({
                premium: this.props.marketing.monthlyPremium,
                premiumPerItem: this.props.marketing.premiumPerItem,
                itemsPerHousehold: this.props.marketing.itemsPerHousehold,
                closingRatio: this.props.marketing.closedRatio,
                itemsPerPolicy: this.props.marketing.itemsPerPolicy,
            });
        }
    };
    refreshData = () => {
        const userId = localStorage.getItem("userId");
        const agencyId = localStorage.getItem("agencyId");
        const dateTo = moment(this.state.selectedDate).endOf('month').format("MM/DD/YYYY");
        const dateFrom = moment(this.state.selectedDate).startOf('month').format("MM/DD/YYYY");
        this.props.getMarketingSummary(agencyId, this.state.monthDts);
        this.props.getActualMarketingSummary(agencyId, dateFrom, dateTo);
    }
    onTelemarketingChange() {
        this.refreshData();
    };
    onSocialMediaChange() {
        this.refreshData();
    };
    onMailerChange() {
        this.refreshData();
    };
    onLiveLeadChange() {
        this.refreshData();
    };
    onDataLeadChange() {
        this.refreshData();
    };
    onAgedLeadChange() {
        this.refreshData();
    };
    onMonthChange(date, dateString){
        if(dateString != ''){
            const userId = localStorage.getItem("userId");
            const agencyId = localStorage.getItem("agencyId");
            console.log(moment(date).endOf('month').format("MM/DD/YYYY"));
            this.props.getMarketingSummary(agencyId, dateString);
            const dateTo = moment(date).endOf('month').format("MM/DD/YYYY");
            const dateFrom = moment(date).startOf('month').format("MM/DD/YYYY");
            this.setState({monthDts: dateString, selectedDate: date, dateFrom: dateFrom, dateTo: dateTo});
            this.props.getVendorSummary(agencyId, dateFrom, dateTo, '');
            this.props.getActualMarketingSummary(agencyId, dateFrom, dateTo);
            this.props.getTelemarketing(agencyId, dateString);
            this.props.getLiveLead(agencyId, dateString);
            this.props.getMailer(agencyId, dateString);
            this.props.getDataLead(agencyId, dateString);
            this.props.getSocialMedia(agencyId, dateString);
            this.props.getAgedLead(agencyId, dateString);
        }else{
            this.setState({monthDts: moment().format("MMM YYYY")});
        }
    }
    handleRadioChange(e) {
        this.setState({showReport: e.target.value});
    }
    render(){

        const telemarketingSummary = this.props.marketingSummary ? this.props.marketingSummary.find(t => t.bucket == 'Telemarketing') : [];
        const liveLeadSummary = this.props.marketingSummary ? this.props.marketingSummary.find(t => t.bucket == 'LiveLead') : [];
        const mailerSummary = this.props.marketingSummary ? this.props.marketingSummary.find(t => t.bucket == 'Mailer') : [];
        const socialMediaSummary = this.props.marketingSummary ? this.props.marketingSummary.find(t => t.bucket == 'SocialMedia') : [];
        const dataWebLeadSummary = this.props.marketingSummary ? this.props.marketingSummary.find(t => t.bucket == 'DataWebLead') : [];
        const agedLeadSummary = this.props.marketingSummary ? this.props.marketingSummary.find(t => t.bucket == 'AgedLead') : [];
        
        const telemarketingActualSummary = this.props.actualMarketingSummary ? this.props.actualMarketingSummary.find(t => t.bucket == 'Telemarketing') : [];
        const liveLeadActualSummary = this.props.actualMarketingSummary ? this.props.actualMarketingSummary.find(t => t.bucket == 'LiveLead') : [];
        const mailerActualSummary = this.props.actualMarketingSummary ? this.props.actualMarketingSummary.find(t => t.bucket == 'Mailer') : [];
        const socialMediaActualSummary = this.props.actualMarketingSummary ? this.props.actualMarketingSummary.find(t => t.bucket == 'SocialMedia') : [];
        const dataWebLeadActualSummary = this.props.actualMarketingSummary ? this.props.actualMarketingSummary.find(t => t.bucket == 'DataWebLead') : [];
        const agedLeadActualSummary = this.props.actualMarketingSummary ? this.props.actualMarketingSummary.find(t => t.bucket == 'AgedLead') : [];
        
        let totalForcastedSpend = 0;
        let totalActualSpend = 0;
        let totalForcastedQuotedHouseholds = 0;
        let totalForcastedQuotedPolicies = 0;
        let totalForcastedQuotedItems = 0;
        let totalForcastedWrittenHouseholds = 0;
        let totalForcastedWrittenPolicies = 0;
        let totalForcastedWrittenItems = 0;
        let totalActualQuotedHouseholds = 0;
        let totalActualQuotedPolicies = 0;
        let totalActualQuotedItems = 0;
        let totalActualWrittenHouseholds = 0;
        let totalActualWrittenPolicies = 0;
        let totalActualWrittenItems = 0;
        let totalForcastedHouseholdAcqCost = 0;
        let totalForcastedPoliciesAcqCost = 0;
        let totalForcastedItemsAcqCost = 0;
        let totalActualHouseholdAcqCost = 0;
        let totalActualPoliciesAcqCost = 0;
        let totalActualItemsAcqCost = 0;

        this.props.marketingSummary &&
            this.props.marketingSummary.forEach(({ forcastedSpend, actualSpend, forcastedQuotedHouseholds, forcastedQuotedPolicies, forcastedQuotedItems, forcastedSoldHouseholds, forcastedSoldPolicies, forcastedSoldItems,
            forcastedHouseholdAcquistionCost, forcastedPolicyAcquistionCost, forcastedItemAcquistionCost, actualHouseholdAcquistionCost, actualPolicyAcquistionCost, actualItemAcquistionCost}) => {
                totalForcastedSpend += forcastedSpend;
                totalActualSpend += actualSpend;
                totalForcastedQuotedHouseholds += forcastedQuotedHouseholds;
                totalForcastedQuotedPolicies += forcastedQuotedPolicies;
                totalForcastedQuotedItems += forcastedQuotedItems;
                totalForcastedWrittenHouseholds += forcastedSoldHouseholds;
                totalForcastedWrittenPolicies += forcastedSoldPolicies;
                totalForcastedWrittenItems += forcastedSoldItems;
                // totalForcastedHouseholdAcqCost += forcastedHouseholdAcquistionCost;
                // totalForcastedPoliciesAcqCost += forcastedPolicyAcquistionCost;
                // totalForcastedItemsAcqCost += forcastedItemAcquistionCost;
                // totalActualHouseholdAcqCost += actualHouseholdAcquistionCost;
                // totalActualPoliciesAcqCost += actualPolicyAcquistionCost;
                // totalActualItemsAcqCost += actualItemAcquistionCost;
            });
        this.props.actualMarketingSummary &&
            this.props.actualMarketingSummary.forEach(({quotedHouseholds, quotedPolicies, quotedItems, writtenHouseholds, writtenPolicies, writtenItems}) => {
                totalActualQuotedHouseholds += quotedHouseholds;
                totalActualQuotedPolicies += quotedPolicies;
                totalActualQuotedItems += quotedItems;
                totalActualWrittenHouseholds += writtenHouseholds;
                totalActualWrittenPolicies += writtenPolicies;
                totalActualWrittenItems += writtenItems;   
            });

            totalForcastedHouseholdAcqCost = totalForcastedWrittenHouseholds > 0 ? totalForcastedSpend / totalForcastedWrittenHouseholds : 0;
            totalForcastedPoliciesAcqCost = totalForcastedWrittenPolicies > 0 ? totalForcastedSpend / totalForcastedWrittenPolicies : 0;
            totalForcastedItemsAcqCost = totalForcastedWrittenItems > 0 ? totalForcastedSpend / totalForcastedWrittenItems : 0;
            totalActualHouseholdAcqCost = totalActualWrittenHouseholds > 0 ? totalActualSpend / totalActualWrittenHouseholds : 0;
            totalActualPoliciesAcqCost = totalActualWrittenPolicies > 0 ? totalActualSpend / totalActualWrittenPolicies : 0;
            totalActualItemsAcqCost = totalActualWrittenItems > 0 ? totalActualSpend / totalActualWrittenItems : 0;
        const styles = {
            tableCell: {
                border: "1px solid black", 
                width: '100px',
                fontSize: '12px'
            },
        };
        const premiumPerPolicy = this.props.marketing.premiumPerItem * this.props.marketing.itemsPerPolicy
        const premiumPerHousehold = this.props.marketing.premiumPerItem * this.props.marketing.itemsPerHousehold

        const writtenItems = this.props.marketing.monthlyPremium / this.props.marketing.premiumPerItem;
        const writtenPolicies = this.props.marketing.monthlyPremium / premiumPerPolicy;
        const writtenHouseholds = this.props.marketing.monthlyPremium / premiumPerHousehold;

        const closingRatio = this.props.marketing.closedRatio / 100;
        const closingRatioPolicies = this.props.marketing.closedRatioPerPolicy / 100;
        const closingRatioHouseholds = this.props.marketing.closedRatioPerHousehold / 100;

        const quotedItems = writtenItems / closingRatio;
        const quotedPolicies = writtenPolicies / closingRatioPolicies;
        const quotedHousehold = writtenHouseholds / closingRatioHouseholds;
        return(
            <Row>
                <Col span={24}>
                    <Row style={{paddingBottom: 5}}>
                        <Col md={8}>
                            <strong>Filter month</strong><br />
                            <DatePicker onChange={this.onMonthChange} picker="month" format="MMM YYYY" value={moment(this.state.monthDts, "MMM YYYY")}  />
                        </Col>
                    </Row>
                    <Row style={{marginBottom: 10}}>
                        <Col span={24}>
                            <Card title="AGENCY GOALS" style={{borderRadius: "10px", overflow: "auto"}} headStyle={{ backgroundColor: '#3D76DD', color: "#fff" }} >
                                <table width="100%">
                                    <thead style={{border: 'solid 1px #000'}}>
                                        <tr style={{backgroundColor: '#fafafa', fontWeight: 'bold', textAlign: 'center', color: '#000'}}>
                                            <td style={{borderRight: 'solid 1px #000'}}>Monthly Premium Goal</td>
                                            <td style={{borderRight: 'solid 1px #000'}}>Quoted Households</td>
                                            <td style={{borderRight: 'solid 1px #000'}}>Quoted Policies</td>
                                            <td style={{borderRight: 'solid 1px #000'}}>Quoted Items</td>
                                            <td style={{borderRight: 'solid 1px #000'}}>Written Households</td>
                                            <td style={{borderRight: 'solid 1px #000'}}>Written Policies</td>
                                            <td>Written Items</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style={{fontWeight: 'bold', textAlign: 'center', borderBottom: 'solid 1px #000'}}>
                                            <td style={{borderLeft: 'solid 1px #000'}}>
                                            {new Intl.NumberFormat("en-US", {
                                                            style: "currency",
                                                            currency: "USD"
                                            }).format(this.props.marketing.monthlyPremium)}
                                            </td>
                                            <td style={{borderLeft: 'solid 1px #000'}}>
                                            {Math.round(quotedHousehold)}
                                            </td>
                                            <td style={{borderLeft: 'solid 1px #000'}}>
                                            {Math.round(quotedPolicies)}
                                            </td>
                                            <td style={{borderLeft: 'solid 1px #000'}}>
                                            {Math.round(quotedItems)}
                                            </td>
                                            <td style={{borderLeft: 'solid 1px #000'}}>
                                            {Math.round(writtenHouseholds)}
                                            </td>
                                            <td style={{borderLeft: 'solid 1px #000'}}>
                                            {Math.round(writtenPolicies)}
                                            </td>
                                            <td style={{borderRight: 'solid 1px #000', borderLeft: 'solid 1px #000'}}>
                                            {Math.round(writtenItems)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Radio.Group style={{marginBottom: 5}} size="small" defaultValue="Marketing" buttonStyle="solid" onChange={this.handleRadioChange} value={this.state.showReport}>
                                <Radio.Button value="Marketing">Marketing</Radio.Button>
                                <Radio.Button value="Cost Acquisition">Cost Acquisition</Radio.Button>
                            </Radio.Group>
                        </Col>  
                    </Row> 
                    {this.state.showReport != "Marketing" ?
                    <Row>
                        <Col span={24}>
                            <CostAcquisition monthDts={this.state.monthDts} dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} />
                        </Col>  
                    </Row>
                    :
                    <Row>
                        <table style={{width: '100%', border: "1px solid black"}}>
                            <thead style={{fontWeight: 'bold', backgroundColor: '#fafafa', borderBottom: "1px solid black"}}>
                                <tr style={{height: '50px', textAlign: 'center'}}>
                                    <th style={{width: '10%'}}>Marketing Bucket</th>
                                    <th colSpan={2} style={{border: "1px solid black"}}>Spend</th>
                                    <th colSpan={2} style={{border: "1px solid black"}}>Quoted Households</th>
                                    <th colSpan={2} style={{border: "1px solid black"}}>Quoted Policies</th>
                                    <th colSpan={2} style={{border: "1px solid black"}}>Quoted Items</th>
                                    <th colSpan={2} style={{border: "1px solid black"}}>Written Households</th>
                                    <th colSpan={2} style={{border: "1px solid black"}}>Written Policies</th>
                                    <th colSpan={2} style={{border: "1px solid black"}}>Written Items</th>
                                    <th colSpan={2} style={{border: "1px solid black"}}>Household Acq. Cost</th>
                                    <th colSpan={2} style={{border: "1px solid black"}}>Policy Acq. Cost</th>
                                    <th colSpan={2} style={{border: "1px solid black"}}>Item Acq. Cost</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th style={styles.tableCell}>FORECASTED</th>
                                    <th style={styles.tableCell}>ACTUALS</th>
                                    <th style={styles.tableCell}>FORECASTED</th>
                                    <th style={styles.tableCell}>ACTUALS</th>
                                    <th style={styles.tableCell}>FORECASTED</th>
                                    <th style={styles.tableCell}>ACTUALS</th>
                                    <th style={styles.tableCell}>FORECASTED</th>
                                    <th style={styles.tableCell}>ACTUALS</th>
                                    <th style={styles.tableCell}>FORECASTED</th>
                                    <th style={styles.tableCell}>ACTUALS</th>
                                    <th style={styles.tableCell}>FORECASTED</th>
                                    <th style={styles.tableCell}>ACTUALS</th>
                                    <th style={styles.tableCell}>FORECASTED</th>
                                    <th style={styles.tableCell}>ACTUALS</th>
                                    <th style={styles.tableCell}>FORECASTED</th>
                                    <th style={styles.tableCell}>ACTUALS</th>
                                    <th style={styles.tableCell}>FORECASTED</th>
                                    <th style={styles.tableCell}>ACTUALS</th>
                                    <th style={styles.tableCell}>FORECASTED</th>
                                    <th style={styles.tableCell}>ACTUALS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{borderBottom: 'solid 1px #ccc'}}>
                                    <td style={{backgroundColor: "#809bce", fontWeight: 'bold', cursor: 'pointer', display: 'flex', justifyContent: 'space-between'}} onClick={() => this.setState({showTelemarketer: !this.state.showTelemarketer})}>
                                        <Text>Telemarketing</Text>
                                        {this.state.showTelemarketer == false ? <RightOutlined /> : <DownOutlined /> }
                                    </td>
                                    <td style={{textAlign: 'center'}}>{telemarketingSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(telemarketingSummary.forcastedSpend)}</td>
                                    <td style={{textAlign: 'center'}}>{telemarketingSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(telemarketingSummary.actualSpend)}</td>
                                    <td style={{textAlign: 'center'}}>{telemarketingSummary && telemarketingSummary.forcastedQuotedHouseholds.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{telemarketingActualSummary && telemarketingActualSummary.quotedHouseholds.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{telemarketingSummary && telemarketingSummary.forcastedQuotedPolicies.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{telemarketingActualSummary && telemarketingActualSummary.quotedPolicies.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{telemarketingSummary && telemarketingSummary.forcastedQuotedItems.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{telemarketingActualSummary && telemarketingActualSummary.quotedItems.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{telemarketingSummary && telemarketingSummary.forcastedSoldHouseholds.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{telemarketingActualSummary && telemarketingActualSummary.writtenHouseholds.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{telemarketingSummary && telemarketingSummary.forcastedSoldPolicies.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{telemarketingActualSummary && telemarketingActualSummary.writtenPolicies.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{telemarketingSummary && telemarketingSummary.forcastedSoldItems.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{telemarketingActualSummary && telemarketingActualSummary.writtenItems.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{telemarketingSummary && telemarketingSummary.forcastedSoldHouseholds > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(telemarketingSummary.forcastedSpend / telemarketingSummary.forcastedSoldHouseholds) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{telemarketingSummary && telemarketingActualSummary && telemarketingSummary.actualSpend > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(telemarketingSummary.actualSpend / telemarketingActualSummary.writtenHouseholds) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{telemarketingSummary && telemarketingSummary.forcastedSoldPolicies > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(telemarketingSummary.forcastedSpend / telemarketingSummary.forcastedSoldPolicies) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{telemarketingSummary && telemarketingActualSummary && telemarketingSummary.actualSpend > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(telemarketingSummary.actualSpend / telemarketingActualSummary.writtenPolicies) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{telemarketingSummary && telemarketingSummary.forcastedSoldItems > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(telemarketingSummary.forcastedSpend / telemarketingSummary.forcastedSoldItems) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{telemarketingSummary && telemarketingActualSummary && telemarketingSummary.actualSpend > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(telemarketingSummary.actualSpend / telemarketingActualSummary.writtenItems) : 0}</td>
                                    {/* <td style={{textAlign: 'center'}}>{telemarketingSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(telemarketingSummary.forcastedHouseholdAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{telemarketingSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(telemarketingSummary.actualHouseholdAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{telemarketingSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(telemarketingSummary.forcastedPolicyAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{telemarketingSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(telemarketingSummary.actualPolicyAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{telemarketingSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(telemarketingSummary.forcastedItemAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{telemarketingSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(telemarketingSummary.actualItemAcquistionCost)}</td> */}
                                </tr>
                                {this.props.telemarketing.map(t => {
                                    console.log(t.name);
                                    const filterLeadSource = this.props.vendorSummary ? this.props.vendorSummary.filter(v => v.typeQuoteLogLeadSourceId == t.typeQuoteLogLeadSourceId) : [];
                                    const actualTelemarketingQuotedItems = filterLeadSource.length > 0 ? filterLeadSource[0].quotedItems : 0;
                                    const actualTelemarketingPerHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].quotedHouseholds : 0;
                                    const actualTelemarketingQuotedPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].quotedPolicies : 0;

                                    const actualTelemarketingSoldItems = filterLeadSource.length > 0 ? filterLeadSource[0].writtenItems : 0;
                                    const actualTelemarketingSoldHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].writtenHouseholds : 0;
                                    const actualTelemarketingSoldPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].writtenPolicies : 0;
                                    return (
                                    this.state.showTelemarketer == true &&
                                    <tr style={{borderBottom: 'solid 1px #ccc', backgroundColor: '#fafafa'}}>
                                        <td style={{backgroundColor: "#fafafa"}}>{t.name}</td>
                                        <td style={{textAlign: 'center'}}>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(parseFloat(t.monthlyCost))}</td>
                                        <td style={{textAlign: 'center'}}>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(parseFloat(t.actualMonthlyCost))}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.quotedHouseholds).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualTelemarketingPerHousehold.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.quotedPolicies).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualTelemarketingQuotedPolicies.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.quotedItems).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualTelemarketingQuotedItems.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.soldHouseholds).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualTelemarketingSoldHousehold.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.soldPolicies).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualTelemarketingSoldPolicies.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.soldItems).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualTelemarketingSoldItems.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{t.soldHouseholds > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.telemarketerMonthlyCost / t.soldHouseholds) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.actualTelemarketerMonthlyCost > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.actualTelemarketerMonthlyCost / actualTelemarketingSoldHousehold) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.soldPolicies > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.telemarketerMonthlyCost / t.soldPolicies) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.actualTelemarketerMonthlyCost > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.actualTelemarketerMonthlyCost / actualTelemarketingSoldPolicies) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.soldItems > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.telemarketerMonthlyCost / t.soldItems) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.actualTelemarketerMonthlyCost > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.actualTelemarketerMonthlyCost / actualTelemarketingSoldItems) : 0}</td>
                                    </tr>
                                    
                                )})}
                                <tr style={{borderBottom: 'solid 1px #ccc'}}>
                                    <td style={{backgroundColor: "#95b8d1", fontWeight: 'bold', cursor: 'pointer', display: 'flex', justifyContent: 'space-between'}} onClick={() => this.setState({showLiveLead: !this.state.showLiveLead})}>
                                        <Text>Live Call Transfer</Text>
                                        {this.state.showLiveLead == false ? <RightOutlined /> : <DownOutlined /> }
                                    </td>
                                    <td style={{textAlign: 'center'}}>{liveLeadSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(liveLeadSummary.forcastedSpend)}</td>
                                    <td style={{textAlign: 'center'}}>{liveLeadSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(liveLeadSummary.actualSpend)}</td>
                                    <td style={{textAlign: 'center'}}>{liveLeadSummary && liveLeadSummary.forcastedQuotedHouseholds.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{liveLeadActualSummary && liveLeadActualSummary.quotedHouseholds.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{liveLeadSummary && liveLeadSummary.forcastedQuotedPolicies.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{liveLeadActualSummary && liveLeadActualSummary.quotedPolicies.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{liveLeadSummary && liveLeadSummary.forcastedQuotedItems.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{liveLeadActualSummary && liveLeadActualSummary.quotedItems.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{liveLeadSummary && liveLeadSummary.forcastedSoldHouseholds.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{liveLeadActualSummary && liveLeadActualSummary.writtenHouseholds.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{liveLeadSummary && liveLeadSummary.forcastedSoldPolicies.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{liveLeadActualSummary && liveLeadActualSummary.writtenPolicies.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{liveLeadSummary && liveLeadSummary.forcastedSoldItems.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{liveLeadActualSummary && liveLeadActualSummary.writtenItems.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{liveLeadSummary && liveLeadSummary.forcastedSoldHouseholds > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(liveLeadSummary.forcastedSpend / liveLeadSummary.forcastedSoldHouseholds) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{liveLeadSummary && liveLeadActualSummary && liveLeadSummary.actualSpend > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(liveLeadSummary.actualSpend / liveLeadActualSummary.writtenHouseholds) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{liveLeadSummary && liveLeadSummary.forcastedSoldPolicies > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(liveLeadSummary.forcastedSpend / liveLeadSummary.forcastedSoldPolicies) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{liveLeadSummary && liveLeadActualSummary && liveLeadSummary.actualSpend > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(liveLeadSummary.actualSpend / liveLeadActualSummary.writtenPolicies) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{liveLeadSummary && liveLeadSummary.forcastedSoldItems > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(liveLeadSummary.forcastedSpend / liveLeadSummary.forcastedSoldItems) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{liveLeadSummary && liveLeadActualSummary && liveLeadSummary.actualSpend > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(liveLeadSummary.actualSpend / liveLeadActualSummary.writtenItems) : 0}</td>
                                    {/* <td style={{textAlign: 'center'}}>{liveLeadSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(liveLeadSummary.forcastedHouseholdAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{liveLeadSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(liveLeadSummary.actualHouseholdAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{liveLeadSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(liveLeadSummary.forcastedPolicyAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{liveLeadSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(liveLeadSummary.actualPolicyAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{liveLeadSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(liveLeadSummary.forcastedItemAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{liveLeadSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(liveLeadSummary.actualItemAcquistionCost)}</td> */}
                                </tr>
                                {this.props.liveLead.map(t => {
                                    const filterLeadSource = this.props.vendorSummary ? this.props.vendorSummary.filter(v => v.typeQuoteLogLeadSourceId == t.typeQuoteLogLeadSourceId) : [];
                                    const actualLiveLeadQuotedItems = filterLeadSource.length > 0 ? filterLeadSource[0].quotedItems : 0;
                                    const actualLiveLeadPerHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].quotedHouseholds : 0;
                                    const actualLiveLeadQuotedPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].quotedPolicies : 0;

                                    const actualLiveLeadSoldItems = filterLeadSource.length > 0 ? filterLeadSource[0].writtenItems : 0;
                                    const actualLiveLeadSoldHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].writtenHouseholds : 0;
                                    const actualLiveLeadSoldPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].writtenPolicies : 0;
                                    return (
                                    this.state.showLiveLead == true &&
                                    <tr style={{borderBottom: 'solid 1px #ccc', backgroundColor: '#fafafa'}}>
                                        <td style={{backgroundColor: "#fafafa"}}>{t.name}</td>
                                        <td style={{textAlign: 'center'}}>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.monthlyCost)}</td>
                                        <td style={{textAlign: 'center'}}>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.actualMonthlyCost)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.quotedHouseholds).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualLiveLeadPerHousehold.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.quotedPolicies).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualLiveLeadQuotedPolicies.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.quotedItems).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualLiveLeadQuotedItems.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.soldHouseholds).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualLiveLeadSoldHousehold.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.soldPolicies).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualLiveLeadSoldPolicies.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.soldItems).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualLiveLeadSoldItems.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{t.soldHouseholds > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.monthlyCost / t.soldHouseholds) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.actualMonthlyCost > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.actualMonthlyCost / actualLiveLeadSoldHousehold) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.soldPolicies > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.monthlyCost / t.soldPolicies) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.actualMonthlyCost > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.actualMonthlyCost / actualLiveLeadSoldPolicies) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.soldItems > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.monthlyCost / t.soldItems) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.actualMonthlyCost > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.actualMonthlyCost / actualLiveLeadSoldItems) : 0}</td>
                                    </tr>
                                )})}
                                <tr style={{borderBottom: 'solid 1px #ccc'}}>
                                    <td style={{backgroundColor: "#b8e0d2", fontWeight: 'bold', cursor: 'pointer', display: 'flex', justifyContent: 'space-between'}} onClick={() => this.setState({showMailer: !this.state.showMailer})}>
                                        <Text>Mailers</Text>
                                        {this.state.showMailer == false ? <RightOutlined /> : <DownOutlined /> }
                                    </td>
                                    <td style={{textAlign: 'center'}}>{mailerSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(mailerSummary.forcastedSpend)}</td>
                                    <td style={{textAlign: 'center'}}>{mailerSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(mailerSummary.actualSpend)}</td>
                                    <td style={{textAlign: 'center'}}>{mailerSummary && mailerSummary.forcastedQuotedHouseholds.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{mailerActualSummary && mailerActualSummary.quotedHouseholds.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{mailerSummary && mailerSummary.forcastedQuotedPolicies.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{mailerActualSummary && mailerActualSummary.quotedPolicies.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{mailerSummary && mailerSummary.forcastedQuotedItems.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{mailerActualSummary && mailerActualSummary.quotedItems.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{mailerSummary && mailerSummary.forcastedSoldHouseholds.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{mailerActualSummary && mailerActualSummary.writtenHouseholds.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{mailerSummary && mailerSummary.forcastedSoldPolicies.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{mailerActualSummary && mailerActualSummary.writtenPolicies.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{mailerSummary && mailerSummary.forcastedSoldItems.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{mailerActualSummary && mailerActualSummary.writtenItems.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{mailerSummary && mailerSummary.forcastedSoldHouseholds > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(mailerSummary.forcastedSpend / mailerSummary.forcastedSoldHouseholds) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{mailerSummary && mailerActualSummary && mailerSummary.actualSpend > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(mailerSummary.actualSpend / mailerActualSummary.writtenHouseholds) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{mailerSummary && mailerSummary.forcastedSoldPolicies > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(mailerSummary.forcastedSpend / mailerSummary.forcastedSoldPolicies) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{mailerSummary && mailerActualSummary && mailerSummary.actualSpend > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(mailerSummary.actualSpend / mailerActualSummary.writtenPolicies) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{mailerSummary && mailerSummary.forcastedSoldItems > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(mailerSummary.forcastedSpend / mailerSummary.forcastedSoldItems) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{mailerSummary && mailerActualSummary && mailerSummary.actualSpend > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(mailerSummary.actualSpend / mailerActualSummary.writtenItems) : 0}</td>
                                    {/* <td style={{textAlign: 'center'}}>{mailerSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(mailerSummary.forcastedHouseholdAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{mailerSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(mailerSummary.actualHouseholdAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{mailerSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(mailerSummary.forcastedPolicyAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{mailerSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(mailerSummary.actualPolicyAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{mailerSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(mailerSummary.forcastedItemAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{mailerSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(mailerSummary.actualItemAcquistionCost)}</td> */}
                                </tr>
                                {this.props.mailer.map(t => {
                                    const filterLeadSource = this.props.vendorSummary ? this.props.vendorSummary.filter(v => v.typeQuoteLogLeadSourceId == t.typeQuoteLogLeadSourceId) : [];
                                    const actualMailerQuotedItems = filterLeadSource.length > 0 ? filterLeadSource[0].quotedItems : 0;
                                    const actualMailerPerHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].quotedHouseholds : 0;
                                    const actualMailerQuotedPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].quotedPolicies : 0;

                                    const actualMailerSoldItems = filterLeadSource.length > 0 ? filterLeadSource[0].writtenItems : 0;
                                    const actualMailerSoldHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].writtenHouseholds : 0;
                                    const actualMailerSoldPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].writtenPolicies : 0;
                                    return (
                                    this.state.showMailer == true &&
                                    <tr style={{borderBottom: 'solid 1px #ccc', backgroundColor: '#fafafa'}}>
                                        <td style={{backgroundColor: "#fafafa"}}>{t.name}</td>
                                        <td style={{textAlign: 'center'}}>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.monthlyCost)}</td>
                                        <td style={{textAlign: 'center'}}>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.actualMonthlyCost)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.quotedHouseholds).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualMailerPerHousehold.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.quotedPolicies).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualMailerQuotedPolicies.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.quotedItems).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualMailerQuotedItems.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.soldHouseholds).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualMailerSoldHousehold.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.soldPolicies).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualMailerSoldPolicies.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.soldItems).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualMailerSoldItems.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{t.soldHouseholds > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.monthlyCost / t.soldHouseholds) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.actualMonthlyCost > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.actualMonthlyCost / actualMailerSoldHousehold) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.soldPolicies > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.monthlyCost / t.soldPolicies) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.actualMonthlyCost > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.actualMonthlyCost / actualMailerSoldPolicies) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.soldItems > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.monthlyCost / t.soldItems) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.actualMonthlyCost > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.actualMonthlyCost / actualMailerSoldItems) : 0}</td>
                                    </tr>
                                )})}
                                <tr style={{borderBottom: 'solid 1px #ccc'}}>
                                    <td style={{backgroundColor: "#d6eadf", fontWeight: 'bold', cursor: 'pointer', display: 'flex', justifyContent: 'space-between'}} onClick={() => this.setState({showSocialMedia: !this.state.showSocialMedia})}>
                                        <Text>Social Media</Text>
                                        {this.state.showSocialMedia == false ? <RightOutlined /> : <DownOutlined /> }
                                    </td>
                                    <td style={{textAlign: 'center'}}>{socialMediaSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(socialMediaSummary.forcastedSpend)}</td>
                                    <td style={{textAlign: 'center'}}>{socialMediaSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(socialMediaSummary.actualSpend)}</td>
                                    <td style={{textAlign: 'center'}}>{socialMediaSummary && socialMediaSummary.forcastedQuotedHouseholds.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{socialMediaActualSummary && socialMediaActualSummary.quotedHouseholds.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{socialMediaSummary && socialMediaSummary.forcastedQuotedPolicies.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{socialMediaActualSummary && socialMediaActualSummary.quotedPolicies.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{socialMediaSummary && socialMediaSummary.forcastedQuotedItems.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{socialMediaActualSummary && socialMediaActualSummary.quotedItems.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{socialMediaSummary && socialMediaSummary.forcastedSoldHouseholds.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{socialMediaActualSummary && socialMediaActualSummary.writtenHouseholds.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{socialMediaSummary && socialMediaSummary.forcastedSoldPolicies.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{socialMediaActualSummary && socialMediaActualSummary.writtenPolicies.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{socialMediaSummary && socialMediaSummary.forcastedSoldItems.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{socialMediaActualSummary && socialMediaActualSummary.writtenItems.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{socialMediaSummary && socialMediaSummary.forcastedSoldHouseholds > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(socialMediaSummary.forcastedSpend / socialMediaSummary.forcastedSoldHouseholds) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{socialMediaSummary && socialMediaActualSummary && socialMediaSummary.actualSpend > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(socialMediaSummary.actualSpend / socialMediaActualSummary.writtenHouseholds) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{socialMediaSummary && socialMediaSummary.forcastedSoldPolicies > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(socialMediaSummary.forcastedSpend / socialMediaSummary.forcastedSoldPolicies) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{socialMediaSummary && socialMediaActualSummary && socialMediaSummary.actualSpend > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(socialMediaSummary.actualSpend / socialMediaActualSummary.writtenPolicies): 0}</td>
                                    <td style={{textAlign: 'center'}}>{socialMediaSummary && socialMediaSummary.forcastedSoldItems > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(socialMediaSummary.forcastedSpend / socialMediaSummary.forcastedSoldItems) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{socialMediaSummary && socialMediaActualSummary && socialMediaSummary.actualSpend > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(socialMediaSummary.actualSpend / socialMediaActualSummary.writtenItems) : 0}</td>
                                    {/* <td style={{textAlign: 'center'}}>{socialMediaSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(socialMediaSummary.forcastedHouseholdAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{socialMediaSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(socialMediaSummary.actualHouseholdAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{socialMediaSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(socialMediaSummary.forcastedPolicyAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{socialMediaSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(socialMediaSummary.actualPolicyAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{socialMediaSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(socialMediaSummary.forcastedItemAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{socialMediaSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(socialMediaSummary.actualItemAcquistionCost)}</td> */}
                                </tr>
                                {this.props.socialMedia.map(t => {
                                    const filterLeadSource = this.props.vendorSummary ? this.props.vendorSummary.filter(v => v.typeQuoteLogLeadSourceId == t.typeQuoteLogLeadSourceId) : [];
                                    const actualQuotedItems = filterLeadSource.length > 0 ? filterLeadSource[0].quotedItems : 0;
                                    const actualPerHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].quotedHouseholds : 0;
                                    const actualQuotedPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].quotedPolicies : 0;

                                    const actualSoldItems = filterLeadSource.length > 0 ? filterLeadSource[0].writtenItems : 0;
                                    const actualSoldHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].writtenHouseholds : 0;
                                    const actualSoldPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].writtenPolicies : 0;
                                    return (
                                    this.state.showSocialMedia == true &&
                                    <tr style={{borderBottom: 'solid 1px #ccc', backgroundColor: '#fafafa'}}>
                                        <td style={{backgroundColor: "#fafafa"}}>{t.name}</td>
                                        <td style={{textAlign: 'center'}}>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.monthlyCost)}</td>
                                        <td style={{textAlign: 'center'}}>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.actualMonthlyCost)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.quotedHouseholds).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualPerHousehold.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.quotedPolicies).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualQuotedPolicies.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.quotedItems).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualQuotedItems.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.soldHouseholds).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualSoldHousehold.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.soldPolicies).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualSoldPolicies.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.soldItems).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualSoldItems.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{t.soldHouseholds > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.monthlyCost / t.soldHouseholds) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.actualMonthlyCost > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.actualMonthlyCost / actualSoldHousehold) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.soldPolicies > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.monthlyCost / t.soldPolicies) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.actualMonthlyCost > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.actualMonthlyCost / actualSoldPolicies) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.soldItems > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.monthlyCost / t.soldItems) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.actualMonthlyCost > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.actualMonthlyCost / actualSoldItems) : 0}</td>
                                    </tr>
                                )})}
                                <tr style={{borderBottom: 'solid 1px #ccc'}}>
                                    <td style={{backgroundColor: "#fcf5c7", fontWeight: 'bold', cursor: 'pointer', display: 'flex', justifyContent: 'space-between'}} onClick={() => this.setState({showDataLead: !this.state.showDataLead})}>
                                        <Text>Live Web Lead</Text>
                                        {this.state.showDataLead == false ? <RightOutlined /> : <DownOutlined /> }
                                    </td>
                                    <td style={{textAlign: 'center'}}>{dataWebLeadSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(dataWebLeadSummary.forcastedSpend)}</td>
                                    <td style={{textAlign: 'center'}}>{dataWebLeadSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(dataWebLeadSummary.actualSpend)}</td>
                                    <td style={{textAlign: 'center'}}>{dataWebLeadSummary && dataWebLeadSummary.forcastedQuotedHouseholds.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{dataWebLeadActualSummary && dataWebLeadActualSummary.quotedHouseholds.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{dataWebLeadSummary && dataWebLeadSummary.forcastedQuotedPolicies.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{dataWebLeadActualSummary && dataWebLeadActualSummary.quotedPolicies.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{dataWebLeadSummary && dataWebLeadSummary.forcastedQuotedItems.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{dataWebLeadActualSummary && dataWebLeadActualSummary.quotedItems.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{dataWebLeadSummary && dataWebLeadSummary.forcastedSoldHouseholds.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{dataWebLeadActualSummary && dataWebLeadActualSummary.writtenHouseholds.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{dataWebLeadSummary && dataWebLeadSummary.forcastedSoldPolicies.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{dataWebLeadActualSummary && dataWebLeadActualSummary.writtenPolicies.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{dataWebLeadSummary && dataWebLeadSummary.forcastedSoldItems.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{dataWebLeadActualSummary && dataWebLeadActualSummary.writtenItems.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{dataWebLeadSummary && dataWebLeadSummary.forcastedSoldHouseholds > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(dataWebLeadSummary.forcastedSpend / dataWebLeadSummary.forcastedSoldHouseholds) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{dataWebLeadSummary && dataWebLeadActualSummary && dataWebLeadSummary.actualSpend > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(dataWebLeadSummary.actualSpend / dataWebLeadActualSummary.writtenHouseholds) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{dataWebLeadSummary && dataWebLeadSummary.forcastedSoldPolicies > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(dataWebLeadSummary.forcastedSpend / dataWebLeadSummary.forcastedSoldPolicies) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{dataWebLeadSummary && dataWebLeadActualSummary && dataWebLeadSummary.actualSpend > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(dataWebLeadSummary.actualSpend / dataWebLeadActualSummary.writtenPolicies) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{dataWebLeadSummary && dataWebLeadSummary.forcastedSoldItems > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(dataWebLeadSummary.forcastedSpend / dataWebLeadSummary.forcastedSoldItems) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{dataWebLeadSummary && dataWebLeadActualSummary && dataWebLeadSummary.actualSpend > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(dataWebLeadSummary.actualSpend / dataWebLeadActualSummary.writtenItems) : 0}</td>
                                    {/* <td style={{textAlign: 'center'}}>{dataWebLeadSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(dataWebLeadSummary.forcastedHouseholdAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{dataWebLeadSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(dataWebLeadSummary.actualHouseholdAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{dataWebLeadSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(dataWebLeadSummary.forcastedPolicyAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{dataWebLeadSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(dataWebLeadSummary.actualPolicyAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{dataWebLeadSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(dataWebLeadSummary.forcastedItemAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{dataWebLeadSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(dataWebLeadSummary.actualItemAcquistionCost)}</td> */}
                                </tr>
                                {this.props.dataLead.map(t => {
                                    const filterLeadSource = this.props.vendorSummary ? this.props.vendorSummary.filter(v => v.typeQuoteLogLeadSourceId == t.typeQuoteLogLeadSourceId) : [];
                                    const actualQuotedItems = filterLeadSource.length > 0 ? filterLeadSource[0].quotedItems : 0;
                                    const actualPerHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].quotedHouseholds : 0;
                                    const actualQuotedPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].quotedPolicies : 0;

                                    const actualSoldItems = filterLeadSource.length > 0 ? filterLeadSource[0].writtenItems : 0;
                                    const actualSoldHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].writtenHouseholds : 0;
                                    const actualSoldPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].writtenPolicies : 0;
                                    return (
                                    this.state.showDataLead == true &&
                                    <tr style={{borderBottom: 'solid 1px #ccc', backgroundColor: '#fafafa'}}>
                                        <td style={{backgroundColor: "#fafafa"}}>{t.name}</td>
                                        <td style={{textAlign: 'center'}}>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.monthlyCost)}</td>
                                        <td style={{textAlign: 'center'}}>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.actualMonthlyCost)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.quotedHouseholds).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualPerHousehold.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.quotedPolicies).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualQuotedPolicies.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.quotedItems).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualQuotedItems.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.soldHouseholds).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualSoldHousehold.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.soldPolicies).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualSoldPolicies.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.soldItems).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualSoldItems.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{t.soldHouseholds > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.monthlyCost / t.soldHouseholds) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.actualMonthlyCost > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.actualMonthlyCost / actualSoldHousehold) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.soldPolicies > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.monthlyCost / t.soldPolicies) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.actualMonthlyCost > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.actualMonthlyCost / actualSoldPolicies) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.soldItems > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.monthlyCost / t.soldItems) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.actualMonthlyCost > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.actualMonthlyCost / actualSoldItems) : 0}</td>
                                    </tr>
                                )})}
                                <tr style={{borderBottom: 'solid 1px #000'}}>
                                    <td style={{backgroundColor: "#f3ffe3", fontWeight: 'bold', cursor: 'pointer', display: 'flex', justifyContent: 'space-between'}} onClick={() => this.setState({showAgedLead: !this.state.showAgedLead})}>
                                        <Text>Aged Leads</Text>
                                        {this.state.showAgedLead == false ? <RightOutlined /> : <DownOutlined /> }
                                    </td>
                                    <td style={{textAlign: 'center'}}>{agedLeadSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(agedLeadSummary.forcastedSpend)}</td>
                                    <td style={{textAlign: 'center'}}>{agedLeadSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(agedLeadSummary.actualSpend)}</td>
                                    <td style={{textAlign: 'center'}}>{agedLeadSummary && agedLeadSummary.forcastedQuotedHouseholds.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{agedLeadActualSummary && agedLeadActualSummary.quotedHouseholds.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{agedLeadSummary && agedLeadSummary.forcastedQuotedPolicies.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{agedLeadActualSummary && agedLeadActualSummary.quotedPolicies.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{agedLeadSummary && agedLeadSummary.forcastedQuotedItems.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{agedLeadActualSummary && agedLeadActualSummary.quotedItems.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{agedLeadSummary && agedLeadSummary.forcastedSoldHouseholds.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{agedLeadActualSummary && agedLeadActualSummary.writtenHouseholds.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{agedLeadSummary && agedLeadSummary.forcastedSoldPolicies.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{agedLeadActualSummary && agedLeadActualSummary.writtenPolicies.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{agedLeadSummary && agedLeadSummary.forcastedSoldItems.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{agedLeadActualSummary && agedLeadActualSummary.writtenItems.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{agedLeadSummary && agedLeadSummary.forcastedSoldHouseholds > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(agedLeadSummary.forcastedSpend / agedLeadSummary.forcastedSoldHouseholds) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{agedLeadSummary && agedLeadActualSummary && agedLeadSummary.actualSpend > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(agedLeadSummary.actualSpend / agedLeadActualSummary.writtenHouseholds) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{agedLeadSummary && agedLeadSummary.forcastedSoldPolicies > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(agedLeadSummary.forcastedSpend / agedLeadSummary.forcastedSoldPolicies) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{agedLeadSummary && agedLeadActualSummary && agedLeadSummary.actualSpend > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(agedLeadSummary.actualSpend / agedLeadActualSummary.writtenPolicies) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{agedLeadSummary && agedLeadSummary.forcastedSoldItems > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(agedLeadSummary.forcastedSpend / agedLeadSummary.forcastedSoldItems) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{agedLeadSummary && agedLeadActualSummary && agedLeadSummary.actualSpend > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(agedLeadSummary.actualSpend / agedLeadActualSummary.writtenItems) : 0}</td>
                                    {/* <td style={{textAlign: 'center'}}>{agedLeadSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(agedLeadSummary.forcastedHouseholdAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{agedLeadSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(agedLeadSummary.actualHouseholdAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{agedLeadSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(agedLeadSummary.forcastedPolicyAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{agedLeadSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(agedLeadSummary.actualPolicyAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{agedLeadSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(agedLeadSummary.forcastedItemAcquistionCost)}</td>
                                    <td style={{textAlign: 'center'}}>{agedLeadSummary && new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(agedLeadSummary.actualItemAcquistionCost)}</td> */}
                                </tr>
                                {this.props.agedLead.map(t => {
                                    const filterLeadSource = this.props.vendorSummary ? this.props.vendorSummary.filter(v => v.typeQuoteLogLeadSourceId == t.typeQuoteLogLeadSourceId) : [];
                                    const actualQuotedItems = filterLeadSource.length > 0 ? filterLeadSource[0].quotedItems : 0;
                                    const actualPerHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].quotedHouseholds : 0;
                                    const actualQuotedPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].quotedPolicies : 0;

                                    const actualSoldItems = filterLeadSource.length > 0 ? filterLeadSource[0].writtenItems : 0;
                                    const actualSoldHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].writtenHouseholds : 0;
                                    const actualSoldPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].writtenPolicies : 0;
                                    return (
                                    this.state.showAgedLead == true &&
                                    <tr style={{borderBottom: 'solid 1px #ccc', backgroundColor: '#fafafa'}}>
                                        <td style={{backgroundColor: "#fafafa"}}>{t.name}</td>
                                        <td style={{textAlign: 'center'}}>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.monthlyCost)}</td>
                                        <td style={{textAlign: 'center'}}>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.actualMonthlyCost)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.quotedHouseholds).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualPerHousehold.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.quotedPolicies).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualQuotedPolicies.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.quotedItems).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualQuotedItems.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.soldHouseholds).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualSoldHousehold.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.soldPolicies).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualSoldPolicies.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{parseFloat(t.soldItems).toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{actualSoldItems.toFixed(2)}</td>
                                        <td style={{textAlign: 'center'}}>{t.soldHouseholds > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.monthlyCost / t.soldHouseholds) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.actualMonthlyCost > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.actualMonthlyCost / actualSoldHousehold) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.soldPolicies > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.monthlyCost / t.soldPolicies) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.actualMonthlyCost > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.actualMonthlyCost / actualSoldPolicies) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.soldItems > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.monthlyCost / t.soldItems) : 0}</td>
                                        <td style={{textAlign: 'center'}}>{t.actualMonthlyCost > 0 ? new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(t.actualMonthlyCost / actualSoldItems) : 0}</td>
                                    </tr>
                                )})}
                                <tr syle={{fontWeight: 'bold'}}>
                                    <td style={{backgroundColor: "#ffb8b1", fontWeight: 'bold'}}>TOTALS</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold'}}>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(totalForcastedSpend)}</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold', color: totalForcastedSpend > totalActualSpend ? 'green' : 'red'}}>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(totalActualSpend)}</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold'}}>{totalForcastedQuotedHouseholds.toLocaleString(undefined, {maximumFractionDigits:2})}</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold', color: totalForcastedQuotedHouseholds < totalActualQuotedHouseholds ? 'green' : 'red'}}>{totalActualQuotedHouseholds.toLocaleString(undefined, {maximumFractionDigits:2})}</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold'}}>{totalForcastedQuotedPolicies.toLocaleString(undefined, {maximumFractionDigits:2})}</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold', color: totalForcastedQuotedPolicies < totalActualQuotedPolicies ? 'green' : 'red'}}>{totalActualQuotedPolicies.toLocaleString(undefined, {maximumFractionDigits:2})}</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold'}}>{totalForcastedQuotedItems.toLocaleString(undefined, {maximumFractionDigits:2})}</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold', color: totalForcastedQuotedItems < totalActualQuotedItems ? 'green' : 'red'}}>{totalActualQuotedItems.toLocaleString(undefined, {maximumFractionDigits:2})}</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold'}}>{totalForcastedWrittenHouseholds.toLocaleString(undefined, {maximumFractionDigits:2})}</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold', color: totalForcastedWrittenHouseholds < totalActualWrittenHouseholds ? 'green' : 'red'}}>{totalActualWrittenHouseholds.toLocaleString(undefined, {maximumFractionDigits:2})}</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold'}}>{totalForcastedWrittenPolicies.toLocaleString(undefined, {maximumFractionDigits:2})}</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold', color: totalForcastedWrittenPolicies < totalActualWrittenPolicies ? 'green' : 'red'}}>{totalActualWrittenPolicies.toLocaleString(undefined, {maximumFractionDigits:2})}</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold'}}>{totalForcastedWrittenItems.toLocaleString(undefined, {maximumFractionDigits:2})}</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold', color: totalForcastedWrittenItems < totalActualWrittenItems ? 'green' : 'red'}}>{totalActualWrittenItems.toLocaleString(undefined, {maximumFractionDigits:2})}</td>

                                    <td style={{textAlign: 'center', fontWeight: 'bold'}}>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(totalForcastedHouseholdAcqCost)}</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold', color: totalForcastedHouseholdAcqCost > totalActualHouseholdAcqCost ? 'green' : 'red'}}>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(totalActualHouseholdAcqCost)}</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold'}}>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(totalForcastedPoliciesAcqCost)}</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold', color: totalForcastedPoliciesAcqCost > totalActualPoliciesAcqCost ? 'green' : 'red'}}>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(totalActualPoliciesAcqCost)}</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold'}}>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(totalForcastedItemsAcqCost)}</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold', color: totalForcastedItemsAcqCost > totalActualItemsAcqCost ? 'green' : 'red'}}>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(totalActualItemsAcqCost)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Row>
                    }
                    <Row style={{paddingTop: 20}}>
                        <Col span={24}>
                            <Collapse width="100%">
                                <Panel header={<Title level={4}>TELEMARKETING BREAKDOWN</Title>} key="1" style={{backgroundColor: "#809bce"}}>
                                    <Telemarketing vendorSummary={this.props.vendorSummary} premiumPerItem={this.state.premiumPerItem} itemsPerHousehold={this.state.itemsPerHousehold} itemsPerPolicy={this.state.itemsPerPolicy} onTelemarketingTotal={this.onTelemarketingChange} closedRatio={this.props.marketing.closedRatio} />
                                </Panel>
                                <Panel header={<Title level={4}>LIVE CALL TRANSFER BREAKDOWN</Title>} key="2" style={{backgroundColor: "#95b8d1"}}>
                                    <LiveLead vendorSummary={this.props.vendorSummary} premiumPerItem={this.state.premiumPerItem} itemsPerHousehold={this.state.itemsPerHousehold} itemsPerPolicy={this.state.itemsPerPolicy} onLiveLeadTotal={this.onLiveLeadChange} />
                                </Panel>
                                <Panel header={<Title level={4}>MAILER BREAKDOWN</Title>} key="4" style={{backgroundColor: "#b8e0d2"}}>
                                    <Mailer vendorSummary={this.props.vendorSummary} premiumPerItem={this.state.premiumPerItem} itemsPerHousehold={this.state.itemsPerHousehold} itemsPerPolicy={this.state.itemsPerPolicy} onMailerTotal={this.onMailerChange} />
                                </Panel>
                                <Panel header={<Title level={4}>SOCIAL MEDIA</Title>} key="5" style={{backgroundColor: "#d6eadf"}}>
                                    <SocialMedia vendorSummary={this.props.vendorSummary} premiumPerItem={this.state.premiumPerItem} itemsPerHousehold={this.state.itemsPerHousehold} itemsPerPolicy={this.state.itemsPerPolicy} onSocialMediaTotal={this.onSocialMediaChange} />
                                </Panel>
                                <Panel header={<Title level={4}>LIVE WEB LEAD</Title>} key="6" style={{backgroundColor: "#fcf5c7"}}>
                                    <DataLead vendorSummary={this.props.vendorSummary} premiumPerItem={this.state.premiumPerItem} itemsPerHousehold={this.state.itemsPerHousehold} itemsPerPolicy={this.state.itemsPerPolicy} onDataLeadTotal={this.onDataLeadChange} />
                                </Panel>
                                <Panel header={<Title level={4}>AGED LEADS</Title>} key="7" style={{backgroundColor: "#f3ffe3"}}>
                                    <AgedLead vendorSummary={this.props.vendorSummary} premiumPerItem={this.state.premiumPerItem} itemsPerHousehold={this.state.itemsPerHousehold} itemsPerPolicy={this.state.itemsPerPolicy} onAgedLeadTotal={this.onAgedLeadChange} />
                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Marketing);