import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Card, Input, InputNumber, Row, Col, Typography, Select, Button, DatePicker, Table, Collapse, Radio, Space, Modal, Checkbox, Spin } from 'antd';
import { SearchOutlined, SaveOutlined, DeleteOutlined, PlusCircleOutlined, StarFilled, PercentageOutlined } from '@ant-design/icons';
import moment from 'moment';
const { Search } = Input;
const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Text } = Typography;


class SoldQuoteLog extends Component {
    constructor(props) {
        super(props);
          this.state = {
            effectiveDate: moment(this.props.effectiveDate).format("MM/DD/YYYY"),
            bindDate: moment(this.props.bindDate).format("MM/DD/YYYY"),
            soldAuto: this.props.auto,
            autoPremium: this.props.autoPremium,
            dwellingSoldCheckboxes: this.props.dwellingSoldCheckboxes, //this.props.dwellingSoldCheckboxes.filter(s => s.checked).length > 0 ? this.props.dwellingSoldCheckboxes : this.props.dwellingCheckboxes,
            specialtySoldCheckboxes: this.props.specialtySoldCheckboxes, //this.props.specialtySoldCheckboxes.filter(s => s.checked).length > 0 ? this.props.specialtySoldCheckboxes : this.props.specialtyCheckboxes,            
            dwellingCheckboxes: this.props.dwellingCheckboxes,
            specialtyCheckboxes: this.props.specialtyCheckboxes,
            originalStatus: this.props.originalStatus,
            soldBundle: this.props.soldBundle,
            soldCommercialAuto: this.props.soldCommercialAuto,
            commercialAutoPremium: this.props.commercialAutoPremium,
            autoBundle: false
          }
          this.handleChange = this.handleChange.bind(this);
          this.handleNumberChange = this.handleNumberChange.bind(this);
          this.handleBindDateChange = this.handleBindDateChange.bind(this);
          this.handleEffectiveDateChange = this.handleEffectiveDateChange.bind(this);
          this.disabledDate = this.disabledDate.bind(this);
          this.handleBundleChange = this.handleBundleChange.bind(this);
          this.handleAutoCheckChange = this.handleAutoCheckChange.bind(this);
    }
    componentDidMount() {
        console.log("soldAuto: " + this.props.auto);
        const agencyId = localStorage.getItem('agencyId');
        this.props.getLeadSource(agencyId);
        this.props.getTypeVendorTypes(agencyId);
        this.props.onSoldChange('soldAuto', this.state.soldAuto);
        this.props.onSoldChange('soldCommercialAuto', this.state.soldCommercialAuto);
        this.props.onSoldChange('soldBindDate', this.state.bindDate);
        this.props.onSoldChange('soldEffectiveDate', this.state.effectiveDate);
        var checkedDwelling = this.state.dwellingCheckboxes.filter(s => s.checked);
        var checkSoldDwelling = this.state.dwellingSoldCheckboxes.filter(s => s.checked);
        var checkedSpecialty = this.state.specialtyCheckboxes.filter(s => s.checked);
        var checkSoldSpecialty = this.state.specialtySoldCheckboxes.filter(s => s.checked);
        if(this.state.originalStatus != 'SOLD'){
            if(checkedDwelling.length > checkSoldDwelling.length){
                this.setState({dwellingSoldCheckboxes: this.state.dwellingCheckboxes});
                //this.props.onDwellingSoldChange(this.state.dwellingSoldCheckboxes);
                if(checkSoldDwelling.length > 0){
                    const data = checkedDwelling;
                    const updatedData = data.map(group => {
                        if (checkSoldDwelling.filter(d => d.id == group.id).length > 0) {
                            const dwelling = checkSoldDwelling.filter(d => d.id == group.id);
                            console.log(dwelling[0]);
                            return {
                            ...group,
                            premium: dwelling[0].premium
                            };
                        } else {
                            return group;
                        }
                    });
                    this.setState({dwellingSoldCheckboxes: updatedData});
                    this.props.onDwellingSoldChange(updatedData);
                }
            }
            if(checkedSpecialty.length > checkSoldSpecialty.length){
                this.setState({specialtySoldCheckboxes: this.state.specialtyCheckboxes});
                //this.props.onSpecialtySoldChange(this.state.specialtySoldCheckboxes);
                if(checkSoldSpecialty.length > 0){
                    const data = checkedSpecialty;
                    const updatedData = data.map(group => {
                        if (checkSoldSpecialty.filter(d => d.id == group.id).length > 0) {
                            const specialty = checkSoldSpecialty.filter(d => d.id == group.id);
                            console.log(specialty[0]);
                            return {
                            ...group,
                            premium: specialty[0].premium
                            };
                        } else {
                            return group;
                        }
                    });
                    this.setState({specialtySoldCheckboxes: updatedData});
                    this.props.onSpecialtySoldChange(updatedData);
                }
            }
        }
    }
    componentDidUpdate(prevProps){
        if(this.props.dwellingCheckboxes != prevProps.dwellingCheckboxes){
            var checkedDwelling = this.props.dwellingCheckboxes.filter(s => s.checked);
            var checkSoldDwelling = this.state.dwellingSoldCheckboxes.filter(s => s.checked);
            //if(checkedDwelling.length > checkSoldDwelling.length){
                //this.setState({dwellingSoldCheckboxes: this.state.dwellingCheckboxes});
                //this.props.onDwellingSoldChange(this.state.dwellingSoldCheckboxes);
                if(checkSoldDwelling.length > 0){
                    const data = checkedDwelling;
                    const updatedData = data.map(group => {
                        if (checkSoldDwelling.filter(d => d.id == group.id).length > 0) {
                            const dwelling = checkSoldDwelling.filter(d => d.id == group.id);
                            console.log(dwelling[0]);
                            return {
                            ...group,
                            premium: dwelling[0].premium
                            };
                        } else {
                            return group;
                        }
                    });
                    this.setState({dwellingSoldCheckboxes: updatedData});
                    this.props.onDwellingSoldChange(updatedData);
                }
            //}
        }
        if(this.props.typeVendorTypes != prevProps.typeVendorTypes){
            console.log("typeVendorTypeId: " + this.props.typeVendorTypeId);
            const autoBundle = this.props.typeVendorTypeId == false ? false : this.props.typeVendorTypes.find(vt => vt.id == this.props.typeVendorTypeId).autoBundle;
            console.log("AutoBundle: " + autoBundle);
            if(autoBundle == true){
                this.setState({soldBundle: autoBundle})
            }
        }
    }
    handleBindDateChange(date, dateString) {
        this.setState({ bindDate: date == null ? moment().format("MM/DD/YYYY") : date});
        this.props.onSoldChange('soldBindDate', date);
    }
    handleEffectiveDateChange(date, dateString) {
        this.setState({ effectiveDate: date == null ? moment().format("MM/DD/YYYY") : date});
        this.props.onSoldChange('soldEffectiveDate', date);
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        this.props.onSoldChange(e.target.name, e.target.value);
    }
    handleNumberChange = (e, name) => {
        this.setState({
            [name]: e
        })
        console.log(name + ":" + e)
        this.props.onSoldChange(name, e);
    }
    handleSpecialtyChange = (e) => {
        const data = this.state.specialtySoldCheckboxes;
        const { value, checked } = e.target;
        const updatedData = data.map(group => {
        if (group.id === value) {
            return {
            ...group,
            checked: group.checked === checked ? null : checked
            };
        } else {
            return group;
        }
        });
        this.setState({ specialtySoldCheckboxes: updatedData }, () => console.log(this.state.specialtySoldCheckboxes));
        this.props.onSpecialtySoldChange(updatedData);
    }
    handleSpecialtyNumberChange = (e, id) => {
        const data = this.state.specialtySoldCheckboxes;
        const updatedData = data.map(group => {
        if (group.id === id) {
            return {
            ...group,
            cnt: e
            };
        } else {
            return group;
        }
        });
        this.setState({ specialtySoldCheckboxes: updatedData }, () => console.log(this.state.specialtySoldCheckboxes));
        this.props.onSpecialtySoldChange(updatedData);
    }
    handleDwellingChange = (e) => {
        const data = this.state.dwellingSoldCheckboxes;
        const { value, checked } = e.target;
        const updatedData = data.map(group => {
        if (group.id === value) {
            return {
            ...group,
            checked: group.checked === checked ? null : checked
            };
        } else {
            return group;
        }
        });
        this.setState({ dwellingSoldCheckboxes: updatedData }, () => console.log(this.state.dwellingSoldCheckboxes));
        this.props.onDwellingSoldChange(updatedData);
    }
    handleDwellingNumberChange = (e, id) => {
        const data = this.state.dwellingSoldCheckboxes;
        const updatedData = data.map(group => {
        if (group.id === id) {
            return {
            ...group,
            cnt: e
            };
        } else {
            return group;
        }
        });
        this.setState({ dwellingSoldCheckboxes: updatedData }, () => console.log(this.state.dwellingSoldCheckboxes));
        this.props.onDwellingSoldChange(updatedData);
    }
    handleDwellingPremiumChange = (e, id) => {
        const data = this.state.dwellingSoldCheckboxes;
        const updatedData = data.map(group => {
        if (group.id === id) {
            return {
            ...group,
            premium: e.target.value
            };
        } else {
            return group;
        }
        });
        this.setState({ dwellingSoldCheckboxes: updatedData }, () => console.log(this.state.dwellingSoldCheckboxes));
        this.props.onDwellingSoldChange(updatedData);
    }
    handleSpecialtyPremiumChange = (e, id) => {
        const data = this.state.specialtySoldCheckboxes;
        const updatedData = data.map(group => {
        if (group.id === id) {
            return {
            ...group,
            premium: e.target.value
            };
        } else {
            return group;
        }
        });
        this.setState({ specialtySoldCheckboxes: updatedData }, () => console.log(this.state.specialtySoldCheckboxes));
        this.props.onSpecialtySoldChange(updatedData);
    }
    disabledDate(current) {
        // Can not select days before today and today
        return current && current < moment(this.props.quoteDate);
    }
    handleBundleChange(e) {
        console.log(e.target.checked);
        this.setState({soldBundle: e.target.checked});
        this.props.onSoldChange(e.target.name, e.target.checked);
    }
    handleAutoCheckChange(e) {
        console.log(e.target.checked);
        if(!e.target.checked)
        {
            this.setState({soldAuto: 0});
            this.setState({autoPremium: 0});
            this.props.onSoldChange('soldAuto', 0);
            this.props.onSoldChange('autoPremium', 0);
        }
    }
    render(){
        const dwellingCnt = this.state.dwellingSoldCheckboxes.filter(s => s.checked && s.name != 'N/A').length > 0 ? 1 : 0;
        const specialtyCnt = this.state.specialtySoldCheckboxes.filter(s => s.checked && s.name != 'N/A').length > 0 ? 1 : 0;
        const soldAutoCnt = this.state.soldAuto > 0 ? 1 : 0
        return(
            <Row style={{border: 'solid 1px #97E45D', padding: '10px', marginTop: '10px'}}>
                <Col span={4} style={{paddingRight: 5}}>
                    <Row>
                        <Col>
                            <label htmlFor="bindDate">Bind Date<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                            <DatePicker 
                                disabledDate={this.disabledDate}
                                onChange={(e) => this.handleBindDateChange(e)} 
                                format={'MM/DD/YYYY'} style={{width: '100%'}} 
                                defaultValue={moment(this.state.bindDate, 'MM/DD/YYYY')} 
                                value={moment(this.state.bindDate, 'MM/DD/YYYY')} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label htmlFor="effectiveDate">Effective Date<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                            <DatePicker onChange={(e) => this.handleEffectiveDateChange(e)} format={'MM/DD/YYYY'} style={{width: '100%'}} defaultValue={moment(this.state.effectiveDate, 'MM/DD/YYYY')} value={moment(this.state.effectiveDate, 'MM/DD/YYYY')} />
                        </Col>
                    </Row>
                </Col>
                {this.state.dwellingSoldCheckboxes.filter(s => s.checked).length > 0 &&
                <Col span={6} style={{paddingRight: 5}}>
                    <div>
                        <label htmlFor="state">Dwelling<small>(select all that apply)</small><span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                        {
                            this.state.dwellingSoldCheckboxes.filter(s => s.checked && s.name != 'N/A').map(d => 
                                <Row key={d.id}>
                                    <Col md={8}>
                                        <Checkbox value={d.id} onChange={(e) => this.handleDwellingChange(e)} checked={d.checked}>{d.name}</Checkbox>
                                    </Col>
                                    <Col md={8}>
                                        {d.checked && 
                                            <InputNumber type="number" min={0} max={15} step={1} style={{width: '100%'}} value={d.cnt} onChange={e => this.handleDwellingNumberChange(e, d.id)} />
                                        }
                                    </Col>
                                    <Col md={8}>
                                        {d.checked && 
                                            <Input type="number" formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')} name='premium' onChange={e => this.handleDwellingPremiumChange(e, d.id)} placeholder="Premium" value={d.premium}  />
                                        }
                                    </Col>
                                </Row>
                            )
                        }
                        
                    </div>
                </Col>
                }
                {/* {this.state.soldAuto > 0  | this.state.soldCommercialAuto > 0 && */}
                <Col span={5} style={{paddingRight: 5}}>
                    {this.state.soldAuto > 0 &&
                    <div>
                        <label htmlFor="state">Auto<small></small><span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                        <Row>
                            <Col md={8}>
                                <Checkbox  onChange={(e) => this.handleAutoCheckChange(e)} checked={this.state.soldAuto > 0 ? true : false}>Auto</Checkbox>
                            </Col>
                            <Col md={8}>
                                <InputNumber type="number" min={0} max={15} step={1} value={this.state.soldAuto} onChange={e => this.handleNumberChange(e, 'soldAuto')} style={{width: '100%'}} />
                            </Col>
                            <Col md={8}>
                                <Input type="number" formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} name='autoPremium' value={this.props.autoPremium} onChange={e => this.handleChange(e)} placeholder="Premium"  />
                            </Col>
                        </Row>
                    </div>
                    }
                </Col>
                {this.state.specialtySoldCheckboxes.filter(s => s.checked).length > 0 &&
                <Col span={6} style={{paddingRight: 5}}>
                    <div>
                        <label htmlFor="specialty">Specialty<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                        {
                            this.state.specialtySoldCheckboxes.filter(s => s.checked && s.name != 'N/A').map(d => 
                                <Row>
                                    <Col md={8}>
                                        <Checkbox value={d.id} onChange={(e) => this.handleSpecialtyChange(e)} checked={d.checked}>{d.name}</Checkbox>
                                    </Col>
                                    <Col md={8}>
                                        {d.checked &&
                                        <InputNumber type="number" min={0} max={15} step={1} style={{width: '100%'}} value={d.cnt} onChange={e => this.handleSpecialtyNumberChange(e, d.id)} />
                                        }
                                    </Col>
                                    <Col md={8}>
                                        {d.checked && 
                                            <Input type="number" formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} name='premium' onChange={e => this.handleSpecialtyPremiumChange(e, d.id)} placeholder="Premium" value={d.premium}  />
                                        }
                                    </Col>
                                </Row>
                            )
                        }
                    </div>
                </Col>
                }
                {dwellingCnt + specialtyCnt + soldAutoCnt == 1 &&
                <Col md={3}>
                    <label htmlFor="soldBundle"></label><br />
                    <Checkbox checked={this.state.soldBundle} name="soldBundle" onChange={(e) => this.handleBundleChange(e)}>Bundle?</Checkbox>
                </Col>
                }
            </Row>
        )
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(SoldQuoteLog);