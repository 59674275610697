import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { actionCreators } from '../store/humbleActions';
import { Card, Button, Input, Col, Row, Typography } from 'antd'
const { Meta } = Card;
const { Text } = Typography;
class Reset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            firstName: '',
            lastName: '',
            company: '',
            email: '',
            loading: false,
            password: '',
            confirmPassword: '',
            registered: false,
            userId: 0
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount(){
        if(this.props.match.params.email){
            const email = this.props.match.params.email;
            this.props.getUserAgent(email);
            this.setState({
                loading: true
            });
        }
    }
    componentDidUpdate(prevProps){
        if(this.props.userAgent != prevProps.userAgent){
            this.setState({
                firstName: this.props.userAgent.firstName,
                email: this.props.userAgent.email,
                password: this.props.userAgent.password,
                userId: this.props.userAgent.id
            });
            localStorage.setItem('agencyId', this.props.userAgent.agencyId);
            localStorage.setItem('status', this.props.userAgent.status);
            localStorage.setItem('userId', this.props.userAgent.id);
        }
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSubmit(event) {
        const password = this.state.password;
        const confirmPassword = this.state.confirmPassword;
        const email = this.state.email;
        const id = this.state.userId;
        if(password == confirmPassword){
            this.props.createUserAgent(email, password);
            this.setState({
                registered: true
            });
        }
    }
    renderRedirect = () => {
        if (this.state.registered == true) {
          return <Redirect to='/Login' />
        }
    }
    render() {  
        return (
            <div>
                {this.renderRedirect()}
                <Row>
                    <img src="humblelogo.jpg" alt="Logo" style={{float: "left", height: 65}} />
                </Row>
                <Row>
                    <Col span={12} offset={6}>
                        <Card title="Create Password" headStyle={{ backgroundColor: '#3D76DD', color: '#fff' }} style={{borderRadius: "20px", overflow: "hidden"}}>
                            <Row>
                                <Col span={18}>
                                    <Meta description={
                                        <div>
                                            <div>
                                                <label htmlFor="firstName">First Name</label><br />
                                                <Input size="large" placeholder="First Name" name="firstName" value={this.state.firstName} onChange={e => this.handleChange(e)} disabled='true' />
                                            </div>
                                            <div>
                                                <label htmlFor="email">Email</label><br />
                                                <Input size="large" placeholder="Email" name="email" value={this.state.email} onChange={e => this.handleChange(e)} disabled='true' />
                                            </div>
                                            <div>
                                                <label htmlFor="password">Password</label><br />
                                                <Input.Password size="large" placeholder="Password" name="password" onChange={e => this.handleChange(e)} />
                                            </div>
                                            <div>
                                                <label htmlFor="password">Confirm Password</label><br />
                                                <Input.Password size="large" placeholder="Confirm Password" name="confirmPassword" onChange={e => this.handleChange(e)} />
                                            </div>
                                        </div>
                                } />
                                </Col>
                            </Row>
                            <Row style={{paddingTop: '10px'}}>
                                <Col>
                                    <Button type='primary' shape="round" onClick={e => this.handleSubmit(e)}>Submit</Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Reset);