import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Row, Col, InputNumber, Typography, Select, List, Input, Button, DatePicker, Cascader, Radio } from 'antd'
import { SaveOutlined } from '@ant-design/icons';
import moment from 'moment';
const { Title, Text } = Typography;
const {Option} = Select

class LiveLead extends Component {
    constructor(props) {
      super(props);
        this.state = {
            liveLeadMonthlyCount: 0,
            liveLeadTransferRation: 0,
            liveLeadQuoteRatio: 0,
            liveLeadCloseRatio: 0,
            actualLiveLeadMonthlyCount: 0,
            actualLiveLeadTransferRation: 0,
            actualLiveLeadQuoteRatio: 0,
            actualLiveLeadCloseRatio: 0,
            liveLeadPerHousehold: 0,
            liveLeadTransferRationCalc: 0,
            liveLeadQuoteRatioCalc: 0,
            liveLeadCloseRatioCalc: 0,
            liveLeadTotalPremium: 0,
            liveLeadMonthlyTransferCount: 0,
            liveLeadMonthlyCost: 0,
            actualLiveLeadMonthlyCost: 0,
            liveLeadMonthlyCostCalc: 0,
            liveLeadCostWrittenItems: 0,
            name: '',
            selectedLeadSourceId: '',
            monthDts: moment().format("MMM YYYY"),
            leadSourceFilter: 'Active',
        };
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
    }
    componentDidMount() {
        const userId = localStorage.getItem('userId');
        const agencyId = localStorage.getItem('agencyId');
        this.props.getTypeVendorTypeLeadSources(agencyId);
        this.props.getLiveLead(agencyId, moment().format("MMM YYYY"));
        this.props.getLeadSource(agencyId);
    }
    componentDidUpdate(prevProps){
        if(prevProps.liveLead != this.props.liveLead){
            this.props.onLiveLeadTotal();
        }
    }
    onLiveLeadMonthlyCountChange = value => {
        this.setState({
            liveLeadMonthlyCount: value
        });
    }
    onActualLiveLeadMonthlyCountChange = value => {
        this.setState({
            actualLiveLeadMonthlyCount: value
        });
    }
    onLiveLeadTransferRationChange = value => {
        this.setState({
            liveLeadTransferRation: value
        });
    }
    onLiveLeadQuoteRatioChange = value => {  
        this.setState({
            liveLeadQuoteRatio: value
        });
    }
    onActualLiveLeadQuoteRatioChange = value => {  
        this.setState({
            actualLiveLeadQuoteRatio: value
        });
    }
    onLiveLeadCloseRatioChange = value => {       
        this.setState({
            liveLeadCloseRatio: value
        });
    }
    onLiveLeadMonthlyTransferCountChange = value => {
        this.setState({
            liveLeadMonthlyTransferCount: value
        });
    }
    onLiveLeadMonthlyCostChange = value => {  
        this.setState({
            liveLeadMonthlyCost: value
        });
    }
    onActualLiveLeadMonthlyCostChange = value => {  
        this.setState({
            actualLiveLeadMonthlyCost: value
        });
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleListClick = (liveLead) => {
        var leadSource = this.props.vendorSummary.filter(v => v.typeQuoteLogLeadSourceId == liveLead.typeQuoteLogLeadSourceId);
        var leadSourceType = this.props.typeVendorTypesLeadSources.filter(c => c.label == leadSource[0].vendorType);
        var selectedValues = liveLead.typeQuoteLogLeadSourceId;
        this.setState({
            id: liveLead.id,
            name: liveLead.name,
            selectedValues: selectedValues,
            selectedLeadSourceId: liveLead.typeQuoteLogLeadSourceId,
            liveLeadMonthlyCount: liveLead.monthlyCount,
            liveLeadQuoteRatio: liveLead.quoteRatio, 
            liveLeadCloseRatio: liveLead.closeRatio,
            liveLeadMonthlyCost: liveLead.monthlyCost,
            actualLiveLeadMonthlyCount: liveLead.actualMonthlyCount,
            actualLiveLeadQuoteRatio: liveLead.actualQuoteRatio,
            actualLiveLeadMonthlyCost: liveLead.actualMonthlyCost,
        });
    }
    handleSave(e) {
        e.preventDefault()
        const userId = localStorage.getItem("userId");
        const agencyId = localStorage.getItem("agencyId");
        const typeQuoteLogLeadSourceId = this.state.selectedLeadSourceId;
        const vendorId = this.state.selectedVendorId;
        const name = this.state.name;
        const monthlyCount = this.state.liveLeadMonthlyCount;
        const transferRate = this.state.liveLeadTransferRation;
        const quoteRatio = this.state.liveLeadQuoteRatio;
        const closeRatio = this.state.liveLeadCloseRatio;
        const premiumPerItem = this.props.premiumPerItem;
        const liveLeadMonthlyTransferCount = this.state.liveLeadMonthlyTransferCount;
        const liveLeadMonthlyCost = this.state.liveLeadMonthlyCost;

        const liveLeadQuoteRatioCalc = this.state.liveLeadMonthlyCount * (this.state.liveLeadQuoteRatio / 100);
        //const liveLeadContactRateCalc = liveLeadTransferRateCalc * (this.state.liveLeadQuoteRatio / 100);
        //const liveLeadCloseRatioCalc = liveLeadQuoteRatioCalc * (this.state.liveLeadCloseRatio / 100);
        const liveLeadQuotedItems = this.props.itemsPerHousehold * liveLeadQuoteRatioCalc;
        const liveLeadPerHousehold = liveLeadQuotedItems / this.props.itemsPerHousehold;
        const liveLeadQuotedPolicies = liveLeadQuotedItems / this.props.itemsPerPolicy;
        //const liveLeadTotalPremium = liveLeadPerHousehold * this.props.premiumPerItem;
        
        const liveLeadSoldItems = liveLeadQuotedItems * (this.state.liveLeadCloseRatio / 100);
        const liveLeadSoldHouseholds = liveLeadPerHousehold * (this.state.liveLeadCloseRatio / 100);
        const liveLeadSoldPolicies = liveLeadQuotedPolicies * (this.state.liveLeadCloseRatio / 100);
        const monthDts = this.state.monthDts;

        const actualLiveLeadCount = this.state.actualLiveLeadMonthlyCount;
        const actualLiveLeadQuoteRatio = this.state.actualLiveLeadQuoteRatio;
        const actualLiveLeadMonthlyCost = this.state.actualLiveLeadMonthlyCost;

        const liveLeadTotalPremium = liveLeadSoldItems * this.props.premiumPerItem;

        const filterLeadSource = this.props.vendorSummary ? this.props.vendorSummary.filter(v => v.typeQuoteLogLeadSourceId == this.state.selectedLeadSourceId) : [];
        const actualLiveLeadQuotedItems = filterLeadSource.length > 0 ? filterLeadSource[0].quotedItems : 0;
        const actualLiveLeadPerHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].quotedHouseholds : 0;
        const actualLiveLeadQuotedPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].quotedPolicies : 0;

        const actualLiveLeadSoldItems = filterLeadSource.length > 0 ? filterLeadSource[0].writtenItems : 0;
        const actualLiveLeadSoldHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].writtenHouseholds : 0;
        const actualLiveLeadSoldPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].writtenPolicies : 0;

        const actualLiveLeadMonthlyCostCalc = this.state.actualLiveLeadMonthlyCost * 1;
        const actualLiveLeadMonthlyCostWrittenItems = actualLiveLeadMonthlyCostCalc / actualLiveLeadSoldItems;
        const actualLiveLeadMonthlyHousehold = actualLiveLeadMonthlyCostCalc / actualLiveLeadSoldHousehold;
        const actualLiveLeadMonthlyPolicy = actualLiveLeadMonthlyCostCalc / actualLiveLeadSoldPolicies;

        const liveLeadMonthlyCostCalc = this.state.liveLeadMonthlyCost * 1;
        const liveLeadMonthlyCostWrittenItems = liveLeadMonthlyCostCalc / liveLeadSoldItems;
        const liveLeadMonthlyHousehold = liveLeadMonthlyCostCalc / liveLeadSoldHouseholds;
        const liveLeadMonthlyPolicy = liveLeadMonthlyCostCalc / liveLeadSoldPolicies;


        this.props.createLiveLead(userId,
            agencyId,
            typeQuoteLogLeadSourceId,
            name, 
            vendorId, 
            monthlyCount, 
            transferRate, 
            quoteRatio, 
            closeRatio, 
            liveLeadMonthlyTransferCount, 
            liveLeadMonthlyCost, 
            liveLeadPerHousehold, 
            liveLeadQuotedPolicies, 
            liveLeadQuotedItems, 
            liveLeadSoldHouseholds, 
            liveLeadSoldPolicies, 
            liveLeadSoldItems, 
            monthDts, 
            liveLeadTotalPremium,
            premiumPerItem,
            actualLiveLeadCount,
            actualLiveLeadQuoteRatio,
            actualLiveLeadMonthlyCost,
            liveLeadMonthlyCostWrittenItems, 
            liveLeadMonthlyHousehold,
            liveLeadMonthlyPolicy,
            actualLiveLeadMonthlyCostWrittenItems, 
            actualLiveLeadMonthlyHousehold, 
            actualLiveLeadMonthlyPolicy);
        this.setState({
            name: '', 
            monthlyCount: '', 
            transferRate: '', 
            quoteRatio: '', 
            closeRatio: '', 
            liveLeadMonthlyTransferCount: '', 
            liveLeadMonthlyCost: ''
        });
    }
    onMonthChange(date, dateString) {
        const agencyId = localStorage.getItem("agencyId");
        if(dateString != ''){
            const dateTo = moment(date).endOf('month').format("MM/DD/YYYY");
            const dateFrom = moment(date).startOf('month').format("MM/DD/YYYY");
            this.props.getVendorSummary(agencyId, dateFrom, dateTo, '');
            this.setState({
                monthDts: dateString
            });
            this.props.getLiveLead(agencyId, moment(date).format("MMM YYYY"));
        }
        else{
            this.setState({
                monthDts: moment().format("MMM YYYY")
            });
        } 
    };
    handleRadioChange(e) {
        this.setState({leadSourceFilter: e.target.value});
    }
    render() {
        //const liveLeadTransferRationCalc = this.state.liveLeadMonthlyCount * (this.state.liveLeadTransferRation / 100);
        const liveLeadQuoteRatioCalc = this.state.liveLeadMonthlyCount * (this.state.liveLeadQuoteRatio / 100);
        const liveLeadCloseRatioCalc = liveLeadQuoteRatioCalc * (this.state.liveLeadCloseRatio / 100);
        const liveLeadQuotedItems = this.props.itemsPerHousehold * liveLeadQuoteRatioCalc;
        const liveLeadPerHousehold = liveLeadQuotedItems / this.props.itemsPerHousehold;
        const liveLeadQuotedPolicies = liveLeadQuotedItems / this.props.itemsPerPolicy;

        const liveLeadSoldItems = liveLeadQuotedItems * (this.state.liveLeadCloseRatio / 100);
        const liveLeadSoldHousehold = liveLeadPerHousehold * (this.state.liveLeadCloseRatio / 100);
        const liveLeadSoldPolicies = liveLeadQuotedPolicies * (this.state.liveLeadCloseRatio / 100);

        const liveLeadTotalPremium = liveLeadSoldItems * this.props.premiumPerItem;

        const filterLeadSource = this.props.vendorSummary ? this.props.vendorSummary.filter(v => v.typeQuoteLogLeadSourceId == this.state.selectedLeadSourceId) : [];

        const actualLiveLeadQuotedItems = filterLeadSource.length > 0 ? filterLeadSource[0].quotedItems : 0;
        const actualLiveLeadPerHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].quotedHouseholds : 0;
        const actualLiveLeadQuotedPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].quotedPolicies : 0;
  
        const actualLiveLeadSoldItems = filterLeadSource.length > 0 ? filterLeadSource[0].writtenItems : 0;
        const actualLiveLeadSoldHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].writtenHouseholds : 0;
        const actualLiveLeadSoldPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].writtenPolicies : 0;

        const actualLiveLeadCloseRatio = actualLiveLeadSoldHousehold > 0 ? actualLiveLeadSoldHousehold / actualLiveLeadPerHousehold : 0;

        const actualLiveLeadTotalPremium = filterLeadSource.length > 0 ? filterLeadSource[0].writtenPremium : 0

        const actualPremiumPerItem = actualLiveLeadTotalPremium > 0 ? actualLiveLeadTotalPremium / actualLiveLeadSoldItems : 0;
        
        const liveLeadMonthlyCostCalc = this.state.liveLeadMonthlyCost * 1;
        const liveLeadMonthlyCostWrittenItems = liveLeadMonthlyCostCalc / liveLeadSoldItems;
        const liveLeadMonthlyPolicy = liveLeadMonthlyCostCalc / liveLeadSoldPolicies;
        const liveLeadMonthlyHousehold = liveLeadMonthlyCostCalc / liveLeadSoldHousehold;

        const actualLiveLeadMonthlyCostCalc = this.state.actualLiveLeadMonthlyCost * 1;
        const actualLiveLeadMonthlyCostWrittenItems = actualLiveLeadMonthlyCostCalc / actualLiveLeadSoldItems;
        const actualLiveLeadMonthlyHousehold = actualLiveLeadMonthlyCostCalc / actualLiveLeadSoldHousehold;
        const actualLiveLeadMonthlyPolicy = actualLiveLeadMonthlyCostCalc / actualLiveLeadSoldPolicies;
        
        const liveLeadCostPerQuotedHousehold = liveLeadMonthlyCostCalc / liveLeadPerHousehold;
        const actualLiveLeadCostPerQuotedHousehold = actualLiveLeadMonthlyCostCalc / actualLiveLeadPerHousehold;

        const onChange = (value) => {
            var liveLeadLst = this.props.liveLead.filter(t => t.typeQuoteLogLeadSourceId == value);
            if(liveLeadLst.length > 0){
                var liveLead = liveLeadLst[0];
                this.setState({
                    id: liveLead.id,
                    name: liveLead.name,
                    selectedValues: value,
                    selectedLeadSourceId: liveLead.typeQuoteLogLeadSourceId,
                    liveLeadMonthlyCount: liveLead.monthlyCount,
                    liveLeadQuoteRatio: liveLead.quoteRatio, 
                    liveLeadCloseRatio: liveLead.closeRatio,
                    liveLeadMonthlyCost: liveLead.monthlyCost,
                    actualLiveLeadMonthlyCount: liveLead.actualMonthlyCount,
                    actualLiveLeadQuoteRatio: liveLead.actualQuoteRatio,
                    actualLiveLeadMonthlyCost: liveLead.actualMonthlyCost,
                });
            }
            else{
                this.setState({
                    id: 0,
                    name: '',
                    selectedValues: value,
                    selectedLeadSourceId: value, 
                    actualLiveLeadCount: 0, 
                    actualLiveLeadQuoteRatio: 0,
                    actualLiveLeadMonthlyCost: 0,
                    liveLeadCount: 0,
                    liveLeadQuoteRatio: 0,
                    liveLeadMonthlyCost: 0
                });
            }
        };

        const styles = {
            disabledInputText: {
                width: '100%', 
                marginLeft: 10, 
                color: '#000'
            },
        };
        return(
            <div>
                <Row>
                    <Col span={10}>
                        <List header={<Title level={4}>LEAD SOURCE</Title>} itemLayout="horizontal" dataSource={this.props.liveLead} renderItem={item => (
                            <List.Item actions={[<a key="list-loadmore-edit" onClick={() => this.handleListClick(item)}>edit</a>]}>
                                <List.Item.Meta title={item.name} />
                            </List.Item>
                        )} /> 
                    </Col>
                    <Col span={14}>
                        <Row style={{paddingBottom: 15}}>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Month
                                </div>
                            </Col>
                            <Col md={16}>
                                <DatePicker style={{ width: '100%', marginLeft: 10 }} onChange={this.onMonthChange} picker="month" format="MMM YYYY" value={moment(this.state.monthDts, "MMM YYYY")} />
                            </Col>
                        </Row>
                        <Row style={{paddingBottom: 15}}>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Lead Source
                                </div>
                            </Col>
                            <Col md={16}>
                                <Radio.Group style={{marginLeft: 10}} size="small" defaultValue="Active" buttonStyle="solid" onChange={this.handleRadioChange} value={this.state.leadSourceFilter}>
                                    <Radio.Button value="Active">Active</Radio.Button>
                                    <Radio.Button value="Inactive">Inactive</Radio.Button>
                                </Radio.Group>
                                <Select
                                    virtual={false}
                                    style={{ width: '100%', marginLeft: 10 }}
                                    dropdownStyle={{ minWidth: "20%" }}
                                    placeholder="Please select"
                                    name="leadSource"
                                    value={this.state.selectedValues}
                                    onChange={onChange}>
                                    {
                                        this.state.leadSourceFilter == "Active" ?
                                        this.props.leadSource.filter(ls => ls.inactive == false).map(ls => 
                                            <Option key={ls.id} value={ls.id}>{ls.name}</Option>
                                        )
                                        :
                                        this.props.leadSource.filter(ls => ls.inactive == true).map(ls => 
                                            <Option key={ls.id} value={ls.id}>{ls.name}</Option>
                                        )
                                    }
                                </Select>
                                {/* <Cascader
                                    style={{ width: '100%', marginLeft: 10 }}
                                    options={this.props.typeVendorTypesLeadSources.filter(c => c.children.length > 0)}
                                    onChange={onChange}
                                    maxTagCount="responsive"
                                    placeholder="Select Lead Source"
                                    value={this.state.selectedValues}
                                /> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                                          
                            </Col>
                            <Col md={8} style={{textAlign: 'center', fontWeight: 'bold'}}>
                                FORECASTED
                            </Col>
                            <Col md={8} style={{textAlign: 'center', fontWeight: 'bold'}}>
                                ACTUALS
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Monthly Live Lead Count
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.liveLeadMonthlyCount} onChange={this.onLiveLeadMonthlyCountChange} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.actualLiveLeadMonthlyCount} onChange={this.onActualLiveLeadMonthlyCountChange} />
                            </Col>
                        </Row>
                        <Row style={{display: 'none'}}>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Transfer Ration
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.liveLeadTransferRation} onChange={this.onLiveLeadTransferRationChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.actualLiveLeadTransferRation} onChange={this.onLiveLeadTransferRationChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Quote Ratio
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.liveLeadQuoteRatio} onChange={this.onLiveLeadQuoteRatioChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.actualLiveLeadQuoteRatio} onChange={this.onActualLiveLeadQuoteRatioChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Close Ratio
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.liveLeadCloseRatio} onChange={this.onLiveLeadCloseRatioChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={new Intl.NumberFormat("en-US", {
                                    style: "percent",
                                    maximumFractionDigits: 2
                                    }).format(actualLiveLeadCloseRatio)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Premium Per Item
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber disabled style={styles.disabledInputText} value={this.props.premiumPerItem} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}  />
                            </Col>
                            <Col md={8}>
                                <InputNumber disabled style={styles.disabledInputText} value={actualPremiumPerItem.toFixed(2)} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Quoted Households
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={liveLeadPerHousehold.toFixed(2)} name="quotedHouseholds" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualLiveLeadPerHousehold} name="quotedHouseholds" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Quoted Polices
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={liveLeadQuotedPolicies.toFixed(2)} name="quotedPolicies" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualLiveLeadQuotedPolicies} name="quotedPolicies" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Quoted Items
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={liveLeadQuotedItems.toFixed(2)} name="quotedItems" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualLiveLeadQuotedItems} name="quotedItems" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Sold Households
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={liveLeadSoldHousehold.toFixed(2)} name="soldHouseholds" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualLiveLeadSoldHousehold} name="soldHouseholds" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Sold Polices
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={liveLeadSoldPolicies.toFixed(2)} name="soldPolicies" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualLiveLeadSoldPolicies} name="soldPolicies" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Sold Items
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={liveLeadSoldItems.toFixed(2)} name="soldItems" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualLiveLeadSoldItems} name="soldItems" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                TOTAL WRITTEN PREMIUM
                            </Col>
                            <Col md={8}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(liveLeadTotalPremium)}</Text>
                            </Col>
                            <Col md={8}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualLiveLeadTotalPremium)}</Text>
                            </Col>
                        </Row>
                        <Row md={24} style={{backgroundColor: "#F5D5CB", fontWeight: 'bold'}}>
                            LIVE LEAD ACQUISITION COST
                        </Row>
                        <Row>
                            <Col md={8}>
                                Live Lead Monthly Cost
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.liveLeadMonthlyCost} onChange={this.onLiveLeadMonthlyCostChange} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.actualLiveLeadMonthlyCost} onChange={this.onActualLiveLeadMonthlyCostChange} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                            Live Lead Cost Per Quoted Household
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(liveLeadCostPerQuotedHousehold)}</Text>
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualLiveLeadCostPerQuotedHousehold)}</Text>
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                Live Lead Item Acquistion Cost 
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(liveLeadMonthlyCostWrittenItems)}</Text>
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualLiveLeadMonthlyCostWrittenItems)}</Text>
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                Live Lead Policy Acquistion Cost 
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(liveLeadMonthlyPolicy)}</Text>
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualLiveLeadMonthlyPolicy)}</Text>
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                Live Lead Household Acquistion Cost 
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                            <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(liveLeadMonthlyHousehold)}</Text>
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualLiveLeadMonthlyHousehold)}</Text>
                            </Col>
                        </Row>
                        <Row style={{paddingBottom: 10}}>
                            <Col span={24} style={{padding: 16}}>
                                <Button shape="round" type="primary" onClick={this.handleSave} style={{ position: 'absolute', right: 0, fontWeight: 'bold', boxShadow: '5px 5px 5px #ccc' }}>
                                    <SaveOutlined /> SAVE
                                </Button>
                            </Col>                           
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(LiveLead);