import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Row, Col, Collapse, Typography, Card, Radio, Progress, Modal, Button, Spin} from 'antd';
import { Liquid, Gauge } from '@ant-design/plots';
import moment from 'moment';
import AgentGoals from './AgentGoals';
const { Panel } = Collapse;
const { Text, Title } = Typography;

class PremiumAverage extends Component {
    constructor(props) {
      super(props);
        this.state = {
            filter: localStorage.getItem('premiumAverageFilter') != null ? localStorage.getItem('premiumAverageFilter') : "premium"
        }
        this.handleRadioChange = this.handleRadioChange.bind(this);
    }
    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        const userId = localStorage.getItem("userId");
        this.props.getAgent(agencyId);
        const savedFilter = localStorage.getItem('premiumAverageFilter') != null ? localStorage.getItem('premiumAverageFilter') : "premium";
        savedFilter == "item" ? this.props.getQuoteLogAverageItem(agencyId) : this.props.getQuoteLogAveragePremium(agencyId);
        this.props.getMarketing(agencyId, moment().format("MMM YYYY"));
    }
    handleRadioChange(e) {
        const agencyId = localStorage.getItem('agencyId');
        localStorage.setItem('premiumAverageFilter', e.target.value);
        this.setState({filter: e.target.value});
        e.target.value == "item" ? this.props.getQuoteLogAverageItem(agencyId) : this.props.getQuoteLogAveragePremium(agencyId);
        this.onFilterChange(e.target.value);
    }
    onFilterChange(filter) {
        this.props.onFilterChange(filter);
    }
    render() {
        var monthlyItemGoal = this.props.isAverageSummaryLoading ? 0 : this.props.marketing.monthlyPremium / this.props.marketing.premiumPerItem;
        //monthlyPremiumGoal / businessDaysinmonth * businessdaysworked - writtenPremium
        var daysWorkedForAverage = this.props.quoteLogAveragePremium.workDaysSoFar - 1;
        var daysLeft = this.props.quoteLogAveragePremium.totalWorkDays - daysWorkedForAverage;
        var yesterdayPremium = this.props.quoteLogAveragePremium.writtenPremium - this.props.quoteLogAveragePremium.writtenPremiumToday;
        //var monthlyPremiumLeft = this.props.marketing.monthlyPremium / this.props.quoteLogAveragePremium.totalWorkDays; //yesterdayPremium;
        var monthlyPremiumLeft = this.props.marketing.monthlyPremium - yesterdayPremium;
        var monthlyItemsLeft = monthlyItemGoal - yesterdayPremium;
        //var dailyAverageGoal = (monthlyPremiumLeft * daysWorkedForAverage) - this.props.quoteLogAveragePremium.writtenPremium;
        var dailyAverageGoal = monthlyPremiumLeft / daysLeft;
        var dailyAverageItemGoal = daysLeft > 0 ? monthlyItemsLeft / daysLeft : monthlyItemsLeft;
        var shouldBeAt = (this.props.marketing.monthlyPremium / this.props.quoteLogAveragePremium.totalWorkDays) * (this.props.quoteLogAveragePremium.workDaysSoFar);
        var shouldBeAtItems = (monthlyItemGoal / this.props.quoteLogAveragePremium.totalWorkDays) * (this.props.quoteLogAveragePremium.workDaysSoFar);
        var goalForToday = shouldBeAt - yesterdayPremium;
        var goalForTodayItems = shouldBeAtItems - yesterdayPremium;
        var stillNeed = goalForToday - this.props.quoteLogAveragePremium.writtenPremiumToday;
        var stillNeedItems = goalForTodayItems - this.props.quoteLogAveragePremium.writtenPremiumToday;

        var percentage = this.props.quoteLogAveragePremium.writtenPremiumToday / goalForToday;
        var percentageItems = this.props.quoteLogAveragePremium.writtenPremiumToday / goalForTodayItems;
        console.log(percentage);
        console.log(percentageItems);
        const lconfig = {
            shape: 'diamond',
            outline: {
                border: 4,
                distance: 8,
            },
                wave: {
                length: 128,
            },
            statistic: {
                content: {
                  formatter: ({ percent }) => this.state.filter == "premium" ? `<span style='font-size: 16px'>Still Need:<br /><span style='font-size: 45px'> ${new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(stillNeed)}</span></span>` : stillNeedItems,
                },
              },
        };
        const config = {
            range: {
              color: 'l(0) 0:#B8E1FF 1:#3D76DD',
            },
            style: {
                stroke: 'red',
            },
            height: 200,
            startAngle: Math.PI,
            endAngle: 2 * Math.PI,
            indicator: null,
            statistic: {
              title: {
                offsetY: -36,
                style: {
                  fontSize: '24px',
                  color: '#4B535E',
                },
                formatter: () => 'Still Need',
              },
              content: {
                style: {
                  fontSize: '36px',
                  lineHeight: '44px',
                  color: '#4B535E',
                },
                formatter: () => this.state.filter == "premium" ? `${new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(stillNeed < 0 ? 0 : stillNeed)}` : Math.round(stillNeedItems),
              },
            },
        };
        return (
            <div>
                <Row style={{marginBottom: 5}}>
                    <Col md={12}>
                        <Radio.Group defaultValue={this.state.filter} buttonStyle="solid" onChange={this.handleRadioChange} style={{display: this.props.isAverageSummaryLoading == true ? 'none' : 'block'}} >
                                <Radio.Button value="premium">Premiums</Radio.Button>
                                <Radio.Button value="item">Items</Radio.Button>
                        </Radio.Group>
                    </Col>
                    <Col md={12}>
                        <Button shape="round" type="primary" onClick={() => this.setState({showModal: true})}>
                            My Agency Metrics
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={24}>
                        <table width='100%'>
                            <thead>
                                <tr style={{backgroundColor: '#3D76DD', fontWeight: 'bold'}}>
                                    <td style={{textAlign: 'center'}} key="writtenPremium">
                                        <Text style={{color: '#fff'}}> {this.state.filter == "premium" ? "Monthly Premium Goal" : "Monthly Items Goal"}</Text>
                                    </td>
                                    <td style={{textAlign: 'center'}} key="workDaysSoFar">
                                        <Text style={{color: '#fff'}}>Business Days Worked</Text>
                                    </td>
                                    <td style={{textAlign: 'center'}} key="dailyAverageSoFar">
                                        <Text style={{color: '#fff'}}>Daily Average</Text>
                                    </td>
                                    <td style={{textAlign: 'center'}} key="totalWorkDays">
                                        <Text style={{color: '#fff'}}>Business Days In Month</Text>
                                    </td>
                                    <td style={{textAlign: 'center'}} key="totalWrittenPremium">
                                        <Text style={{color: '#fff'}}>{this.state.filter == "premium" ? "Written Premium" : "Written Items"}</Text>
                                    </td>
                                    <td style={{textAlign: 'center'}} key="totalPremium">
                                        <Text style={{color: '#fff'}}>{this.state.filter == "premium" ? "Total Premium Trending" : "Total Items Trending"}</Text>
                                    </td>
                                    {this.state.filter == "item" &&
                                    <td style={{textAlign: 'center'}} key="totalVCItemTrending">
                                        <Text style={{color: '#fff'}}>VC Items Trending</Text>
                                    </td>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                <tr ref={this.myRef} key="totalRow" style={{fontWeight: 'bold'}}>
                                    <td style={{textAlign: 'center'}} key="monthlyPremium">
                                        <Text>{
                                            this.state.filter == "premium" ? 
                                                new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(this.props.marketing.monthlyPremium) 
                                                :
                                                Math.round(monthlyItemGoal)
                                            }
                                        </Text>
                                    </td>
                                    <td style={{textAlign: 'center'}} key="workDaysSoFar">
                                        <Text>{new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 3 }).format(this.props.quoteLogAveragePremium.workDaysSoFar)}</Text>
                                    </td>
                                    <td style={{textAlign: 'center'}} key="dailyAverageSoFar">
                                        <Text>{
                                            this.state.filter == "premium" ? 
                                                new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(this.props.quoteLogAveragePremium.dailyAverageSoFar)
                                                :
                                                Math.round(this.props.quoteLogAveragePremium.dailyAverageSoFar)
                                            }
                                        </Text>
                                    </td>
                                    <td style={{textAlign: 'center'}} key="totalWorkDays">
                                        <Text>{this.props.quoteLogAveragePremium.totalWorkDays}</Text>
                                    </td>
                                    <td style={{textAlign: 'center'}} key="writtenPremium">
                                        <Text>{
                                            this.state.filter == "premium" ? 
                                                new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(this.props.quoteLogAveragePremium.writtenPremium)
                                                :
                                                Math.round(this.props.quoteLogAveragePremium.writtenPremium)
                                            }
                                        </Text>
                                    </td>
                                    <td style={{textAlign: 'center'}} key="totalPremium">
                                        <Text>{
                                            this.state.filter == "premium" ? 
                                                new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(this.props.quoteLogAveragePremium.totalPremium)
                                                :
                                                Math.round(this.props.quoteLogAveragePremium.totalPremium)
                                            }
                                        </Text>
                                    </td>
                                    {this.state.filter == "item" &&
                                    <td style={{textAlign: 'center'}} key="totalVCItemTrending">
                                        <Text>
                                            {Math.round(this.props.quoteLogAveragePremium.trendingVCItems)}
                                        </Text>
                                    </td>
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
                {/* <Row>
                    <Col md={24}>
                        <strong>Holidays:</strong>
                        {
                            this.props.quoteLogAveragePremium.monthHolidays &&
                            this.props.quoteLogAveragePremium.monthHolidays.map(h =>
                                <p>{h.localName} - {moment(h.date).format("MM/DD/YYYY")}</p>
                            )
                        }
                    </Col>
                </Row> */}
                <Row>
                    <Col md={6}>
                        <Card size='small'>
                            {this.state.filter == "premium" ? "WRITTEN PREMIUM" : "WRITTEN ITEMS"}<br /><small>At Start Of Day</small>
                            <Title level={4}>
                                {
                                    this.props.isAverageSummaryLoading == true ?
                                    <Spin /> :
                                    this.state.filter == "premium" ? 
                                        new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(yesterdayPremium)
                                    :
                                    yesterdayPremium
                                }
                            </Title>
                        </Card>
                        <Card size='small'>
                            SHOULD BE AT <br /><small>By End Of Day</small><br />
                            <Title level={4}>
                                {
                                    this.props.isAverageSummaryLoading == true ?
                                    <Spin /> :
                                    this.state.filter == "premium" ? 
                                        new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(shouldBeAt)
                                    :
                                        Math.round(shouldBeAtItems)
                                }
                            </Title>
                        </Card>
                        <Card size='small'>
                            GOAL FOR TODAY<br />
                            <Title level={4}>
                                {
                                    this.props.isAverageSummaryLoading == true ?
                                    <Spin /> :
                                    this.state.filter == "premium" ? 
                                        goalForToday < 0 ? '$0.00' : new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(goalForToday)
                                    :
                                        goalForTodayItems < 0 ? 0 : Math.round(goalForTodayItems)
                                }
                            </Title>
                        </Card>
                        <Card size='small'>
                            WRITTEN TODAY<br />
                            <Title level={4}>
                                {
                                    this.props.isAverageSummaryLoading == true ?
                                    <Spin /> :
                                    this.state.filter == "premium" ?
                                        new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(this.props.quoteLogAveragePremium.writtenPremiumToday)
                                    :
                                        Math.round(this.props.quoteLogAveragePremium.writtenPremiumToday)
                                }
                            </Title>
                        </Card>
                    </Col>
                    <Col md={18} style={{textAlign: 'center'}}>
                        {/* <Liquid {...config} percent={percentage} />  */}
                        {/* <Gauge {...config} percent={this.state.filter == "premium" ? percentage : percentageItems}  />*/}
                        <Progress width={350} type="circle" percent={this.state.filter == "premium" ? percentage * 100 : percentageItems * 100} 
                            format={() =>
                                <div style={{fontSize: 36}}>
                                Still Need<br/>
                                {
                                    this.props.isAverageSummaryLoading == true ?
                                    <Spin /> :
                                    this.state.filter == "premium" ? new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(stillNeed < 0 ? 0 : stillNeed) : stillNeedItems < 0 ? 0 : Math.round(stillNeedItems)
                                }
                                </div>  
                            }
                        />
                    </Col>
                </Row>
                <Modal width="80%"
                    title="Sales Agent Goals"
                    visible={this.state.showModal}
                    onCancel={() => this.setState({showModal: false})}
                    footer={[
                    <Button key="submit" type="primary" onClick={() => this.setState({showModal: false})}>
                        Close
                    </Button>,
                    ]}>
                    <AgentGoals viewOnly={true} loggedInAgent="All" />
                </Modal>
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(PremiumAverage);                   