import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { actionCreators } from '../store/humbleActions';
import { LineChartOutlined, FunnelPlotOutlined, BarChartOutlined } from '@ant-design/icons';
import { List, Typography, Row, Col, Avatar, DatePicker, Cascader, Space, Button, Select, Radio, Spin, Tooltip, Table, Input } from 'antd';
import { SearchOutlined, CheckOutlined, CopyOutlined } from '@ant-design/icons';
import moment from 'moment';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { SHOW_CHILD } = Cascader;
const { Option } = Select;
const { Text } = Typography;

class EMQuoteLog extends Component {
    constructor(props) {
      super(props);
        this.state = {
            dateTo: this.props.dateTo,
            dateFrom: this.props.dateFrom,
            reportFilter: 'All',
        }
    }
    
    componentDidMount(){
        const userId = localStorage.getItem('userId');
        const agencyId = localStorage.getItem('agencyId');
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        this.props.getAgent(agencyId);
        const typeReport = this.state.typeReport;
        this.props.getEMQuoteLogReport(agencyId, dateFrom, dateTo, this.state.reportFilter);
        this.props.getTypeVendorTypeLeadSources(agencyId);
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleTableSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleTableSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#97E45D' : undefined }} />,
        onFilter: (value, record) =>
        record != null &&
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
    });
    render() {
        const filterLeadSource = [];
        this.props.leadSource.map (ls =>
            filterLeadSource.push({
                text: ls.name,
                value: ls.name
            })
        );
        const filterAgent = [];
        this.props.agent.filter(a => a.status != "5").map (ls =>
            filterAgent.push({
                text: ls.firstName,
                value: ls.firstName
            })
        );
        const filterReferringAgent = [];
        filterReferringAgent.push({
            text: "",
            value: ""
        })
        this.props.referringAgent.map (ra =>
            filterReferringAgent.push({
                text: ra.name,
                value: ra.name
            })
        );
        const filterObjection = [];
        this.props.objection.map (o =>
            filterObjection.push({
                text: o.name,
                value: o.name
            })
        );
        const filterIneligible = [];
        this.props.ineligible.map (o =>
            filterIneligible.push({
                text: o.name,
                value: o.name
            })
        );
        filterReferringAgent.sort();
        const dateFormat = 'MM/DD/YYYY';
        var showFullNote = this.state.showFullNote;
        const columns = [
            {
                title: 'Quote Date',
                dataIndex: 'dts',
                key: 'dts',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.dts : [],
                render: date => moment(date).format(dateFormat),
            },
            {
                title: 'Bind Date',
                dataIndex: 'bindDate',
                key: 'bindDate',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.binddate : [],
                render: date => date != null ? moment(date).format(dateFormat) : '',
            },
            {
                title: 'Agent',
                dataIndex: 'agent',
                key: 'agent',
                filters: filterAgent,
                filterMultiple: true,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.agent : [],
                onFilter: (value, record) => record.agent.indexOf(value) === 0,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                filters: [
                    // {
                    //     text: 'SOLD',
                    //     value: 'SOLD',
                    // },
                    {
                        text: 'HOT',
                        value: 'HOT',
                    },
                    {
                        text: 'WARM',
                        value: 'WARM',
                    },
                    {
                        text: 'COLD',
                        value: 'COLD',
                    },
                    {
                        text: 'TERMINATED',
                        value: 'TERMINATED',
                    },
                  ],
                  filterMultiple: true,
                  filteredValue: this.state.filteredInfo ? this.state.filteredInfo.status : [],
                  //defaultFilteredValue: this.state.tableSearch.filter(t => t.searchIndex == 'status').length > 0 ? [this.state.tableSearch.filter(t => t.searchIndex == 'status')[0].searchText] : [],
                  onFilter: (value, record) => record.status.indexOf(value) === 0,
            },
            {
                title: 'First Name',
                dataIndex: 'firstName',
                key: 'firstName',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.firstName : [],
                //defaultFilteredValue: this.state.tableSearch.filter(t => t.searchIndex == 'firstName').length > 0 ? [this.state.tableSearch.filter(t => t.searchIndex == 'firstName')[0].searchText] : [],
                ...this.getColumnSearchProps('firstName'),
            },
            {
                title: 'Last Name',
                dataIndex: 'lastName',
                key: 'lastName',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.lastName : [],
                ...this.getColumnSearchProps('lastName'),
            },
            {
                title: 'Lead Source',
                dataIndex: 'leadSource',
                key: 'leadSource',
                render: (text, record) => this.props.leadSource.filter(l => l.id == text).length == 0 ? text : this.props.leadSource.find(l => l.id == text).name,
                //...this.getColumnSearchProps('leadSource'),
                filters: filterLeadSource,
                filterMultiple: true,
                filterSearch: true,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.leadSource : [],
                onFilter: (value, record) => record.leadSource.indexOf(value) === 0,
            },
            {
                title: 'Dwelling',
                dataIndex: 'dwelling',
                key: 'dwelling',
                width: '10%',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.dwelling : [],
                ...this.getColumnSearchProps('dwelling'),
            },
            {
                title: 'Auto',
                dataIndex: 'auto',
                key: 'auto',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.auto : [],
                ...this.getColumnSearchProps('auto'),
            },
            {
                title: 'Comm. Auto',
                dataIndex: 'commercialAuto',
                key: 'commercialAuto',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.commercialAuto : [],
                ...this.getColumnSearchProps('commercialAuto'),
            },
            {
                title: 'Specialty',
                dataIndex: 'specialty',
                key: 'specialty',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.specialty : [],
                ...this.getColumnSearchProps('specialty'),
            },
            {
                title: 'Objection',
                dataIndex: 'objection',
                key: 'objection',
                filters: filterObjection,
                filterMultiple: true,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.objection : [],
                onFilter: (value, record) => record.objection.indexOf(value) === 0,
                // filteredValue: this.state.filteredInfo ? this.state.filteredInfo.objection : [],
                // ...this.getColumnSearchProps('objection'),
            },
            {
                title: 'Ineligible',
                dataIndex: 'ineligible',
                key: 'ineligible',
                filters: filterIneligible,
                filterMultiple: true,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.ineligible : [],
                onFilter: (value, record) => record.ineligible.indexOf(value) === 0,
                // filteredValue: this.state.filteredInfo ? this.state.filteredInfo.ineligible : [],
                // ...this.getColumnSearchProps('ineligible'),
            },
            {
                title: 'Zip',
                dataIndex: 'zip',
                key: 'zip',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.zip : [],
                ...this.getColumnSearchProps('zip'),
            },
            {
                title: 'Notes',
                dataIndex: 'notes',
                key: 'notes',
                width: 500,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.notes : [],
                render: (text, record) => {
                return(
                    text.length > 80 && showFullNote != record.id ?
                    <div>
                        <Tooltip title="copy to clipboard" >
                            <Button type="primary" size="small" shape="circle" icon={<CopyOutlined />} style={{display: text.length == 0 ? 'none' : 'block'}} onClick={() => this.copyToClipboard(text)} />
                        </Tooltip>
                        <Text>{text.substring(0, 80) + "..."}</Text>
                        <Button type="link" onClick={() => this.setState({showFullNote: record.id})}>read more</Button>
                    </div>
                    :
                    <div>
                        <Tooltip title="copy to clipboard" >
                            <Button type="primary" size="small" shape="circle" icon={<CopyOutlined />} style={{display: text.length == 0 ? 'none' : 'block'}} onClick={() => this.copyToClipboard(text)} />
                        </Tooltip>
                        <Text>{text}</Text>
                        {text.length > 80 &&
                        <Button type="link" onClick={() => this.setState({showFullNote: ''})}>read less</Button>
                        }
                    </div>
                )
                // <div>{text.length > 80 && showFullNote == false && text.substring(0, 80) + "..."} {text.length > 80 && <Button type="link" onClick={() => this.setState({showFullNote: true})}>read more</Button>}  {text.length <= 80 && text}</div>
                },
                ...this.getColumnSearchProps('notes'),
            },
            {
                title: 'Bundle',
                dataIndex: 'bundle',
                key: 'bundle',
                filters: [
                    {
                        text: 'Yes',
                        value: true,
                    },
                    {
                        text: 'No',
                        value: false,
                    },
                ],
                filterMultiple: false,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.bundle : [],
                align: 'center',
                render: (text, record) => text == true ? <CheckOutlined style={{fontSize: '20px'}} /> : "",
                onFilter: (value, record) => record.bundle === value
            },
            {
                title: 'Self Gen Quotes',
                dataIndex: 'selfGenerated',
                key: 'selfGenerated',
                filters: [
                    {
                        text: 'Yes',
                        value: true,
                    },
                    {
                        text: 'No',
                        value: false,
                    },
                ],
                filterMultiple: false,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.selfGenerated : [],
                align: 'center',
                render: (text, record) => text == true ? <CheckOutlined style={{fontSize: '20px'}} /> : "",
                onFilter: (value, record) => record.selfGenerated === value
            },
            {
                title: 'Requote / Aged Lead',
                dataIndex: 'requote',
                key: 'requote',
                filters: [
                    {
                        text: 'Yes',
                        value: true,
                    },
                    {
                        text: 'No',
                        value: false,
                    },
                ],
                filterMultiple: false,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.requote : [],
                align: 'center',
                render: (text, record) => text == true ? <CheckOutlined style={{fontSize: '20px'}} /> : "",
                onFilter: (value, record) => record.requote === value
            },
            {
                title: 'Dwelling Ins Score',
                dataIndex: 'insuranceScore',
                key: 'insuranceScore',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.insuranceScore : [],
                ...this.getColumnSearchProps('insuranceScore'),
            },
            {
                title: 'Auto Ins Score',
                dataIndex: 'insuranceScoreAuto',
                key: 'insuranceScoreAuto',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.insuranceScore : [],
                ...this.getColumnSearchProps('insuranceScoreAuto'),
            },
            {
                title: 'Premium',
                dataIndex: 'totalPremium',
                key: 'totalPremium',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.premium : [],
                render: (text, record) => new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(text),
                ...this.getColumnSearchProps('totalPremium'),
            },
            {
                title: 'Referring Agent',
                dataIndex: 'referringAgentName',
                key: 'referringAgentName',
                filters: filterReferringAgent,
                filterMultiple: true,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.referringAgentName : [],
                //render: (text, record) => this.props.referringAgent && text != 0 ? this.props.referringAgent.find(ra => ra.id == text).name : "",
                onFilter: (value, record) => record.referringAgentName == value,
                
            },
            {
                title: '',
                key: 'action',
                render: (text, record) => <a onClick={() => this.handleTableClick(record)}>Edit</a>,
            },
        ];
        const loggedInAgentName = this.props.loggedInAgentName;
        const filteredColumns = this.props.filteredColumns;
        const onlySelf = this.props.onlySelf;
        return (
            <div>
                <Row>
                    <Col span={24} style={{textAlign: "center"}}> 
                        {
                            this.props.isEMQuoteLogLoading ?
                            <div style={{textAlign: 'center'}}> <Spin></Spin><p><Title level={3}>Loading EM Activity Log</Title></p></div>:
                            <div>
                                <Table dataSource={onlySelf == false ? this.props.emQuoteLog : this.props.emQuoteLog.filter(a => a.agent == loggedInAgentName)} columns={filteredColumns} pagination={{ pageSize: 50 }}  />
                            </div>
                        }
                        
                    </Col>
                </Row>
            </div>
        );
    }
    
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(EMQuoteLog);