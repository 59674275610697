import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Select, Table, Row, DatePicker, Col, Space, Button, Radio, Input, InputNumber, Typography } from 'antd';
import { DownOutlined, UpOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const { Search } = Input;

class LeadSourceAgentSummary extends Component {
    constructor(props) {
        super(props);
          this.state = {
            
        };
    }
    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        this.props.getLeadSourceAgentSummary(agencyId, this.props.dateFrom, this.props.dateTo, this.props.typeQuoteLogLeadSourceId);
    }
    componentDidUpdate(prevProps){
        if(this.props.typeQuoteLogLeadSourceId != prevProps.typeQuoteLogLeadSourceId){
            const agencyId = localStorage.getItem('agencyId');
            this.props.getLeadSourceAgentSummary(agencyId, this.props.dateFrom, this.props.dateTo, this.props.typeQuoteLogLeadSourceId);
        }
    };
    render(){
        const dateFormat = 'MM/DD/YYYY';
        const columns = [
            {
                title: 'Lead Source',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Agent',
                dataIndex: 'agent',
                key: 'agent',
            },
            {
                title: 'Written Premium',
                dataIndex: 'writtenPremium',
                key: 'writtenPremium',
                align: 'center',
                render: text => new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(text),
            },
            {
                title: 'Total Spend Per Agent',
                dataIndex: 'quotedHouseholds',
                key: 'quotedHouseholds',
                align: 'center',
                render: text => new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(text * this.props.actualHouseholdCost),
            },
            {
                title: 'Cost PerQuoted Household',
                dataIndex: 'quotedHouseholds',
                key: 'quotedHouseholds',
                align: 'center',
                render: text => new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(text * this.props.actualHouseholdCost),
            },
            {
                title: 'Quoted Policy',
                dataIndex: 'quotedPolicies',
                key: 'quotedPolicies',
                align: 'center',
                render: text => new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(text),
            },
            {
                title: 'Quoted Item',
                dataIndex: 'quotedItems',
                key: 'quotedItems',
                align: 'center',
                render: text => new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(text),
            },
            {
                title: 'Written Households',
                dataIndex: 'writtenHouseholds',
                key: 'writtenHouseholds',
                align: 'center',
                render: text => new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(text),
            },
            {
                title: 'Written Policies',
                dataIndex: 'writtenPolicies',
                key: 'writtenPolicies',
                align: 'center',
                render: text => new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(text),
            },
            {
                title: 'Written Items',
                dataIndex: 'writtenItems',
                key: 'writtenItems',
                align: 'center',
                render: text => new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(text),
            },
          ];
        return(
            <div>
                
                <Row>
                    <Col span={24}>
                    {//this.state.isSaving == false &&
                        //<Table dataSource={this.props.leadSourceAgentSummary} columns={columns} pagination={{ pageSize: 50 }} />
                    }
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <table style={{width: '100%'}}>
                            <thead style={{backgroundColor: '#3D76DD', fontWeight: 'bold', textAlign: 'center', color: '#fff'}}>
                                <tr>
                                    <td>Lead Source</td>
                                    <td>Agent</td>
                                    <td>Written Premium</td>
                                    <td>Quoted<br /> Households</td>
                                    <td>Cost per <br /> Quoted Household</td>
                                    <td>Total Spend <br /> Per Agent</td>
                                    <td>Cost per <br /> Quoted Policy</td>
                                    <td>Cost per <br /> Quoted Item</td>
                                    <td>Household <br /> Acq. Cost</td>
                                    <td>Policy <br /> Acq. Cost</td>
                                    <td>Item <br /> Acq. Cost</td>
                                </tr>
                            </thead>
                            <tbody>
                            {this.props.leadSourceAgentSummary.map(a => {
                                var costPerHousehold = this.props.actualHouseholdCost * a.quotedHouseholds;
                                return <tr style={{textAlign: 'center', borderBottom: 'solid 1px #ccc', height: 50}}>
                                    <td style={{textAlign: 'left'}}>{a.name}</td>
                                    <td style={{textAlign: 'left'}}>{a.agent}</td>
                                    <td>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(a.writtenPremium)}</td>
                                    <td>{a.quotedHouseholds}</td>
                                    <td>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(this.props.actualHouseholdCost)}</td>
                                    <td>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(costPerHousehold)}</td>
                                    <td>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(costPerHousehold / a.quotedPolicies)}</td>
                                    <td>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(costPerHousehold / a.quotedItems)}</td>
                                    <td>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(a.writtenHouseholds > 0 ? costPerHousehold / a.writtenHouseholds : 0)}</td>
                                    <td>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(a.writtenPolicies > 0 ? costPerHousehold / a.writtenPolicies : 0)}</td>
                                    <td>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(a.writtenItems > 0 ? costPerHousehold / a.writtenItems : 0)}</td>
                                </tr>      
                            })}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(LeadSourceAgentSummary);