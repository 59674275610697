import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Row, Col, InputNumber, Typography, Select, Button, List, Input, DatePicker, Cascader, Radio } from 'antd'
import { SaveOutlined } from '@ant-design/icons';
import moment from 'moment'
const { Title, Text } = Typography;
const {Option} = Select

class SocialMedia extends Component {
    constructor(props) {
      super(props);
        this.state = {
            socialMediaMonthlyCount: 0,
            socialMediaTransferRatio: 0,
            socialMediaQuoteRatio: 0,
            actualSocialMediaMonthlyCount: 0,
            actualSsocialMediaTransferRatio: 0,
            actualSsocialMediaQuoteRatio: 0,
            socialMediaCloseRatio: 0,
            socialMediaPerHousehold: 0,
            socialMediaTransferRatioCalc: 0,
            socialMediaQuoteRatioCalc: 0,
            socialMediaCloseRatioCalc: 0,
            socialMediaTotalPremium: 0,
            socialMediaCampaignCount: 0,
            socialMediaMonthlyCost: 0,
            actualSocialMediaMonthlyCost: 0,
            socialMediaMonthlyCostCalc: 0,
            socialMediaCostPerItems: 0,
            selectedLeadSourceId: '',
            name: '',
            selectedVendorId: '',
            monthDts: moment().format("MMM YYYY"),
            leadSourceFilter: 'Active',
        };
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
    }
    componentDidMount() {
        const userId = localStorage.getItem('userId');
        const agencyId = localStorage.getItem('agencyId');
        this.props.getTypeVendorTypeLeadSources(agencyId);
        this.props.getSocialMedia(agencyId, moment().format("MMM YYYY"));
        this.props.getLeadSource(agencyId);
    }
    componentDidUpdate(prevProps) {
        if(prevProps.socialMedia != this.props.socialMedia){
            this.props.onSocialMediaTotal();
        }
    }
    onSocialMediaMonthlyCountChange = value => {
        this.setState({
            socialMediaMonthlyCount: value
        });
    }
    onSocialMediaTransferRatioChange = value => {
        this.setState({
            socialMediaTransferRatio: value
        });
    }
    onSocialMediaQuoteRatioChange = value => {
        this.setState({
            socialMediaQuoteRatio: value
        });
    }
    onActualSocialMediaMonthlyCountChange = value => {
        this.setState({
            actualSocialMediaMonthlyCount: value
        });
    }
    onActualSocialMediaTransferRatioChange = value => {
        this.setState({
            actualSocialMediaTransferRatio: value
        });
    }
    onActualSocialMediaQuoteRatioChange = value => {
        this.setState({
            actualSocialMediaQuoteRatio: value
        });
    }
    onSocialMediaCloseRatioChange = value => {
        this.setState({
            socialMediaCloseRatio: value
        });
    }
    onSocialMediaCampaignCountChange = value => {
        this.setState({
            socialMediaCampaignCount: value
        });
    }
    onSocialMediaMonthlyCostChange = value => {
        this.setState({
            socialMediaMonthlyCost: value
        });
    }
    onActualSocialMediaCampaignCountChange = value => {
        this.setState({
            actualSocialMediaCampaignCount: value
        });
    }
    onActualSocialMediaMonthlyCostChange = value => {
        this.setState({
            actualSocialMediaMonthlyCost: value
        });
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    onMonthChange(date, dateString) {
        const agencyId = localStorage.getItem("agencyId");
        if(dateString != ''){
            const dateTo = moment(date).endOf('month').format("MM/DD/YYYY");
            const dateFrom = moment(date).startOf('month').format("MM/DD/YYYY");
            this.props.getVendorSummary(agencyId, dateFrom, dateTo, '');
            this.setState({
                monthDts: dateString
            });
            this.props.getSocialMedia(agencyId, moment(date).format("MMM YYYY"));
        }
        else{
            this.setState({
                monthDts: moment().format("MMM YYYY")
            });
        } 
    };
    handleVendorChange = (e) => {
        var vendor = this.props.vendors.find(v => v.id == e)
        const socialMediaTransferRatio = vendor.rate;
        const socialMediaCloseRatio = vendor.closeRatio;
        const socialMediaQuoteRatio = vendor.quoteRatio;
        const selectedVendorId = vendor.id;
        this.setState({
            vendor: e,
            selectedVendorId: selectedVendorId,
            socialMediaTransferRatio: socialMediaTransferRatio,
            socialMediaCloseRatio: socialMediaCloseRatio,
            socialMediaQuoteRatio: socialMediaQuoteRatio
        });
    }
    handleListClick = (socialMedia) => {
        var leadSource = this.props.vendorSummary.filter(v => v.typeQuoteLogLeadSourceId == socialMedia.typeQuoteLogLeadSourceId);
        var leadSourceType = this.props.typeVendorTypesLeadSources.filter(c => c.label == leadSource[0].vendorType);
        var selectedValues = socialMedia.typeQuoteLogLeadSourceId;
        this.setState({
            id: socialMedia.id,
            name: socialMedia.name,
            selectedValues: selectedValues,
            selectedLeadSourceId: socialMedia.typeQuoteLogLeadSourceId,
            vendor: String(socialMedia.vendorId),
            socialMediaMonthlyCount: socialMedia.monthlyCount, 
            socialMediaTransferRatio: socialMedia.transferRatio, 
            socialMediaQuoteRatio: socialMedia.quoteRatio, 
            socialMediaCloseRatio: socialMedia.closeRatio,
            socialMediaCampaignCount: socialMedia.campaignCount,
            socialMediaMonthlyCost: socialMedia.monthlyCost,
            actualSocialMediaMonthlyCount: socialMedia.actualMonthlyCount, 
            actualSocialMediaTransferRatio: socialMedia.actualTransferRatio, 
            actualSocialMediaQuoteRatio: socialMedia.actualQuoteRatio, 
            actualSocialMediaCloseRatio: socialMedia.actualCloseRatio,
            actualSocialMediaCampaignCount: socialMedia.actualCampaignCount,
            actualSocialMediaMonthlyCost: socialMedia.actualMonthlyCost
        });
    }
    handleSave(e) {
        e.preventDefault()
        const userId = localStorage.getItem("userId");
        const agencyId = localStorage.getItem("agencyId");
        const typeQuoteLogLeadSourceId = this.state.selectedLeadSourceId;
        const vendorId = 0;
        const name = this.state.name;
        const monthlyCount = this.state.socialMediaMonthlyCount;
        const transferRate = this.state.socialMediaTransferRatio;
        const quoteRatio = this.state.socialMediaQuoteRatio;
        const closeRatio = this.state.socialMediaCloseRatio;
        const campaignCount = this.state.socialMediaCampaignCount;
        const monthlyCost = this.state.socialMediaMonthlyCost;
        const actualMonthlyCount = this.state.actualSocialMediaMonthlyCount;
        const actualTransferRate = this.state.actualSocialMediaTransferRatio;
        const actualQuoteRatio = this.state.actualSocialMediaQuoteRatio;
        const actualCampaignCount = this.state.actualSocialMediaCampaignCount;
        const actualMonthlyCost = this.state.actualSocialMediaMonthlyCost;
        const premiumPerItem = this.props.premiumPerItem;
        const monthDts = this.state.monthDts;

        const socialMediaTransferRateCalc = this.state.socialMediaMonthlyCount * (this.state.socialMediaTransferRatio / 100);
        const socialMediaQuoteRatioCalc = socialMediaTransferRateCalc * (this.state.socialMediaQuoteRatio / 100);
        const socialMediaCloseRatioCalc = socialMediaQuoteRatioCalc * (this.state.socialMediaCloseRatio / 100);
        const socialMediaQuotedItems = this.props.itemsPerHousehold * socialMediaQuoteRatioCalc;
        const socialMediaPerHousehold = socialMediaQuotedItems / this.props.itemsPerHousehold;
        const socialMediaQuotedPolicies = socialMediaQuotedItems / this.props.itemsPerPolicy;

        const socialMediaSoldItems = socialMediaQuotedItems * (this.state.socialMediaCloseRatio / 100);
        const socialMediaSoldHousehold = socialMediaPerHousehold * (this.state.socialMediaCloseRatio / 100);
        const socialMediaSoldPolicies = socialMediaQuotedPolicies * (this.state.socialMediaCloseRatio / 100);

        const socialMediaTotalPremium = socialMediaSoldItems * this.props.premiumPerItem;

        const filterLeadSource = this.props.vendorSummary ? this.props.vendorSummary.filter(v => v.typeQuoteLogLeadSourceId == this.state.selectedLeadSourceId) : [];
        const actualSocialMediaQuotedItems = filterLeadSource.length > 0 ? filterLeadSource[0].quotedItems : 0;
        const actualSocialMediaPerHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].quotedHouseholds : 0;
        const actualSocialMediaQuotedPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].quotedPolicies : 0;

        const actualSocialMediaSoldItems = filterLeadSource.length > 0 ? filterLeadSource[0].writtenItems : 0;
        const actualSocialMediaSoldHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].writtenHouseholds : 0;
        const actualSocialMediaSoldPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].writtenPolicies : 0;

        const actualSocialMediaMonthlyCostCalc = this.state.actualSocialMediaMonthlyCost * 1;
        const actualSocialMediaMonthlyCostWrittenItems = actualSocialMediaMonthlyCostCalc / actualSocialMediaSoldItems;
        const actualSocialMediaMonthlyHousehold = actualSocialMediaMonthlyCostCalc / actualSocialMediaSoldHousehold;
        const actualSocialMediaMonthlyPolicy = actualSocialMediaMonthlyCostCalc / actualSocialMediaSoldPolicies;

        const socialMediaMonthlyCostCalc = this.state.socialMediaMonthlyCost * 1;
        const socialMediaMonthlyCostWrittenItems = socialMediaMonthlyCostCalc / socialMediaSoldItems;
        const socialMediaMonthlyHousehold = socialMediaMonthlyCostCalc / socialMediaSoldHousehold;
        const socialMediaMonthlyPolicy = socialMediaMonthlyCostCalc / socialMediaSoldPolicies;

        this.props.createSocialMedia(
            userId,
            agencyId,
            typeQuoteLogLeadSourceId,
            name,
            vendorId, 
            monthlyCount, 
            transferRate, 
            quoteRatio, 
            closeRatio, 
            campaignCount, 
            monthlyCost,
            actualMonthlyCount, 
            actualTransferRate, 
            actualQuoteRatio, 
            actualCampaignCount, 
            actualMonthlyCost,
            socialMediaPerHousehold, 
            socialMediaQuotedPolicies, 
            socialMediaQuotedItems, 
            socialMediaSoldHousehold, 
            socialMediaSoldPolicies, 
            socialMediaSoldItems, 
            monthDts, 
            socialMediaTotalPremium,
            premiumPerItem,
            socialMediaMonthlyCostWrittenItems,
            socialMediaMonthlyHousehold,
            socialMediaMonthlyPolicy,
            actualSocialMediaMonthlyCostWrittenItems,
            actualSocialMediaMonthlyHousehold,
            actualSocialMediaMonthlyPolicy);
        this.setState({
            name: '', 
            socialMediaMonthlyCount: '', 
            socialMediaTransferRatio: '', 
            socialMediaQuoteRatio: '', 
            socialMediaCloseRatio: '',
            socialMediaCampaignCount: '',
            socialMediaMonthlyCost: '',
            selectedValues: ''
        });
        
    }
    handleRadioChange(e) {
        this.setState({leadSourceFilter: e.target.value});
    }
    render() {
        const socialMediaTransferRatioCalc = this.state.socialMediaMonthlyCount * (this.state.socialMediaTransferRatio / 100);
        const socialMediaQuoteRatioCalc = socialMediaTransferRatioCalc * (this.state.socialMediaQuoteRatio / 100);
        const socialMediaCloseRatioCalc = socialMediaQuoteRatioCalc * (this.state.socialMediaCloseRatio / 100);

        const socialMediaQuotedItems = this.props.itemsPerHousehold * socialMediaQuoteRatioCalc;
        const socialMediaPerHousehold = socialMediaQuotedItems / this.props.itemsPerHousehold;
        const socialMediaQuotedPolicies = socialMediaQuotedItems / this.props.itemsPerPolicy;

        const socialMediaTotalPremium = socialMediaPerHousehold * this.props.premiumPerItem;

        const socialMediaSoldItems = socialMediaQuotedItems * (this.state.socialMediaCloseRatio / 100);
        const socialMediaSoldHousehold = socialMediaPerHousehold * (this.state.socialMediaCloseRatio / 100);
        const socialMediaSoldPolicies = socialMediaQuotedPolicies * (this.state.socialMediaCloseRatio / 100);

        const filterLeadSource = this.props.vendorSummary ? this.props.vendorSummary.filter(v => v.typeQuoteLogLeadSourceId == this.state.selectedLeadSourceId) : [];
        const actualSocialMediaQuotedItems = filterLeadSource.length > 0 ? filterLeadSource[0].quotedItems : 0;
        const actualSocialMediaPerHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].quotedHouseholds : 0;
        const actualSocialMediaQuotedPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].quotedPolicies : 0;

        const actualSocialMediaSoldItems = filterLeadSource.length > 0 ? filterLeadSource[0].writtenItems : 0;
        const actualSocialMediaSoldHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].writtenHouseholds : 0;
        const actualSocialMediaSoldPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].writtenPolicies : 0;

        const actualSocialMediaCloseRatio = actualSocialMediaSoldHousehold > 0 ? actualSocialMediaSoldHousehold / actualSocialMediaPerHousehold : 0;
        const actualSocialMediaTotalPremium = filterLeadSource.length > 0 ? filterLeadSource[0].writtenPremium : 0
        const actualPremiumPerItem = actualSocialMediaTotalPremium > 0 ? actualSocialMediaTotalPremium / actualSocialMediaSoldItems : 0;

        const actualSocialMediaMonthlyCostCalc = this.state.actualSocialMediaMonthlyCost * 1;
        const actualSocialMediaCostPerItems = actualSocialMediaMonthlyCostCalc / actualSocialMediaSoldItems;
        const actualSocialMediaMonthlyHousehold = actualSocialMediaMonthlyCostCalc / actualSocialMediaSoldHousehold;
        const actualSocialMediaMonthlyPolicy = actualSocialMediaMonthlyCostCalc / actualSocialMediaSoldPolicies;

        const socialMediaMonthlyCostCalc = this.state.socialMediaMonthlyCost * 1;
        const socialMediaMonthlyCostWrittenItems = socialMediaMonthlyCostCalc / socialMediaSoldItems;
        const socialMediaMonthlyHousehold = socialMediaMonthlyCostCalc / socialMediaSoldHousehold;
        const socialMediaMonthlyPolicy = socialMediaMonthlyCostCalc / socialMediaSoldPolicies;

        const socialMediaCostPerQuotedHousehold = socialMediaMonthlyCostCalc / socialMediaPerHousehold;
        const actualSocialMediaCostPerQuotedHousehold = actualSocialMediaMonthlyCostCalc / actualSocialMediaPerHousehold;

        const onChange = (value) => {
            var socialMediaLst = this.props.socialMedia.filter(t => t.typeQuoteLogLeadSourceId == value);
            if(socialMediaLst.length > 0){
                var socialMedia = socialMediaLst[0];
                this.setState({
                    id: socialMedia.id,
                    name: socialMedia.name,
                    selectedValues: value,
                    selectedLeadSourceId: socialMedia.typeQuoteLogLeadSourceId,
                    vendor: String(socialMedia.vendorId),
                    socialMediaMonthlyCount: socialMedia.monthlyCount, 
                    socialMediaTransferRatio: socialMedia.transferRatio, 
                    socialMediaQuoteRatio: socialMedia.quoteRatio, 
                    socialMediaCloseRatio: socialMedia.closeRatio,
                    socialMediaCampaignCount: socialMedia.campaignCount,
                    socialMediaMonthlyCost: socialMedia.monthlyCost,
                    actualSocialMediaMonthlyCount: socialMedia.actualMonthlyCount, 
                    actualSocialMediaTransferRatio: socialMedia.actualTransferRatio, 
                    actualSocialMediaQuoteRatio: socialMedia.actualQuoteRatio, 
                    actualSocialMediaCloseRatio: socialMedia.actualCloseRatio,
                    actualSocialMediaCampaignCount: socialMedia.actualCampaignCount,
                    actualSocialMediaMonthlyCost: socialMedia.actualMonthlyCost
                });
            }
            else{
                this.setState({
                    id: 0,
                    name: '',
                    selectedValues: value,
                    selectedLeadSourceId: value, 
                    socialMediaMonthlyCount: 0,
                    socialMediaTransferRatio: 0,
                    socialMediaQuoteRatio: 0,
                    actualSocialMediaMonthlyCount: 0,
                    actualSsocialMediaTransferRatio: 0,
                    actualSsocialMediaQuoteRatio: 0,
                    socialMediaCloseRatio: 0,
                    socialMediaPerHousehold: 0,
                    socialMediaTransferRatioCalc: 0,
                    socialMediaQuoteRatioCalc: 0,
                    socialMediaCloseRatioCalc: 0,
                    socialMediaTotalPremium: 0,
                    socialMediaCampaignCount: 0,
                    socialMediaMonthlyCost: 0,
                    actualSocialMediaMonthlyCost: 0,
                    socialMediaMonthlyCostCalc: 0,
                    socialMediaCostPerItems: 0,
                });
            }
        };
        const styles = {
            disabledInputText: {
                width: '100%', 
                marginLeft: 10, 
                color: '#000'
            },
        };
        return(
            <div>
                <Row>
                    <Col span={10}>
                        <List header={<Title level={4}>LEAD SOURCE</Title>} itemLayout="horizontal" dataSource={this.props.socialMedia} renderItem={item => (
                            <List.Item actions={[<a key="list-loadmore-edit" onClick={() => this.handleListClick(item)}>edit</a>]}>
                                <List.Item.Meta title={item.name} />
                            </List.Item>
                        )} /> 
                    </Col>
                    <Col span={14}>
                        <Row style={{paddingBottom: 15}}>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Month
                                </div>
                            </Col>
                            <Col md={16}>
                                <DatePicker style={{ width: '100%', marginLeft: 10 }} onChange={this.onMonthChange} picker="month" format="MMM YYYY" value={moment(this.state.monthDts, "MMM YYYY")}   />
                            </Col>
                        </Row>
                        <Row style={{paddingBottom: 15}}>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Lead Source
                                </div>
                            </Col>
                            <Col md={16}>
                                <Radio.Group style={{marginLeft: 10}} size="small" defaultValue="Active" buttonStyle="solid" onChange={this.handleRadioChange} value={this.state.leadSourceFilter}>
                                    <Radio.Button value="Active">Active</Radio.Button>
                                    <Radio.Button value="Inactive">Inactive</Radio.Button>
                                </Radio.Group>
                                <Select
                                    virtual={false}
                                    style={{ width: '100%', marginLeft: 10 }}
                                    dropdownStyle={{ minWidth: "20%" }}
                                    placeholder="Please select"
                                    name="leadSource"
                                    value={this.state.selectedValues}
                                    onChange={onChange}>
                                    {
                                        this.state.leadSourceFilter == "Active" ?
                                        this.props.leadSource.filter(ls => ls.inactive == false).map(ls => 
                                            <Option key={ls.id} value={ls.id}>{ls.name}</Option>
                                        )
                                        :
                                        this.props.leadSource.filter(ls => ls.inactive == true).map(ls => 
                                            <Option key={ls.id} value={ls.id}>{ls.name}</Option>
                                        )
                                    }
                                </Select>
                                {/* <Cascader
                                    style={{ width: '100%', marginLeft: 10 }}
                                    options={this.props.typeVendorTypesLeadSources.filter(c => c.children.length > 0)}
                                    onChange={onChange}
                                    maxTagCount="responsive"
                                    placeholder="Select Lead Source"
                                    value={this.state.selectedValues}
                                /> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                                          
                            </Col>
                            <Col md={8} style={{textAlign: 'center', fontWeight: 'bold'}}>
                                FORECASTED
                            </Col>
                            <Col md={8} style={{textAlign: 'center', fontWeight: 'bold'}}>
                                ACTUALS
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Monthly Data Lead Count
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={999999} step={1} style={{width: '100%', marginLeft: 10 }} value={this.state.socialMediaMonthlyCount} onChange={this.onSocialMediaMonthlyCountChange} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={999999} step={1} style={{width: '100%', marginLeft: 10 }} value={this.state.actualSocialMediaMonthlyCount} onChange={this.onActualSocialMediaMonthlyCountChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Transfer Ratio
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={100} step={1} style={{width: '100%', marginLeft: 10 }} value={this.state.socialMediaTransferRatio} onChange={this.onSocialMediaTransferRatioChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={100} step={1} style={{width: '100%', marginLeft: 10 }} value={this.state.actualSocialMediaTransferRatio} onChange={this.onActualSocialMediaTransferRatioChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Quote Ratio
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={100} step={1} style={{width: '100%', marginLeft: 10 }} value={this.state.socialMediaQuoteRatio} onChange={this.onSocialMediaQuoteRatioChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={100} step={1} style={{width: '100%', marginLeft: 10 }} value={this.state.actualSocialMediaQuoteRatio} onChange={this.onActualSocialMediaQuoteRatioChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Close Ratio
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={100} step={1} style={{width: '100%', marginLeft: 10 }} value={this.state.socialMediaCloseRatio} onChange={this.onSocialMediaCloseRatioChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={100} step={1} style={{width: '100%', marginLeft: 10 }} value={new Intl.NumberFormat("en-US", {
                                    style: "percent",
                                    maximumFractionDigits: 2
                                    }).format(actualSocialMediaCloseRatio)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Premium Per Item
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber disabled style={styles.disabledInputText} value={this.props.premiumPerItem} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}  />
                            </Col>
                            <Col md={8}>
                                <InputNumber disabled style={styles.disabledInputText} value={actualPremiumPerItem.toFixed(2)} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Quoted Households
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={socialMediaPerHousehold.toFixed(2)} name="quotedHouseholds" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualSocialMediaPerHousehold} name="quotedHouseholds" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Quoted Polices
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={socialMediaQuotedPolicies.toFixed(2)} name="quotedPolicies" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualSocialMediaQuotedPolicies} name="quotedPolicies" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Quoted Items
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={socialMediaQuotedItems.toFixed(2)} name="quotedItems" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualSocialMediaQuotedItems} name="quotedItems" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Sold Households
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={socialMediaSoldHousehold.toFixed(2)} name="soldHouseholds" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualSocialMediaSoldHousehold} name="soldHouseholds" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Sold Polices
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={socialMediaSoldPolicies.toFixed(2)} name="soldPolicies" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualSocialMediaSoldPolicies} name="soldPolicies" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Sold Items
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={socialMediaSoldItems.toFixed(2)} name="soldItems" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={actualSocialMediaSoldItems} name="soldItems" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                TOTAL WRITTEN PREMIUM
                            </Col>
                            <Col md={8}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(socialMediaTotalPremium)}</Text>
                            </Col>
                            <Col md={8}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualSocialMediaTotalPremium)}</Text>
                            </Col>
                        </Row>
                        <Row md={24} style={{backgroundColor: "#F5D5CB", fontWeight: 'bold'}}>
                            SOCIAL MEDIA ACQUISITION COST MONTHLY
                        </Row>
                        <Row>
                            <Col md={8}>
                                Social Media Monthly Cost
                            </Col>
                            <Col md={8}>
                                <InputNumber min={.1} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.socialMediaMonthlyCost} onChange={this.onSocialMediaMonthlyCostChange} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={.1} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.actualSocialMediaMonthlyCost} onChange={this.onActualSocialMediaMonthlyCostChange} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                Social Media Cost Per Quoted Household
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(socialMediaCostPerQuotedHousehold)}</Text>
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualSocialMediaCostPerQuotedHousehold)}</Text>
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                Social Media Item Acquistion Cost 
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(socialMediaMonthlyCostWrittenItems)}</Text>
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualSocialMediaCostPerItems)}</Text>
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                Social Media Policy Acquistion Cost 
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(socialMediaMonthlyPolicy)}</Text>
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualSocialMediaMonthlyPolicy)}</Text>
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                Social Media Household Acquistion Cost 
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}> {new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(socialMediaMonthlyHousehold)}</Text>
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualSocialMediaMonthlyHousehold)}</Text>
                            </Col>
                        </Row>
                        <Row style={{paddingBottom: 10}}>
                            <Col span={24} style={{padding: 16}}>
                                <Button shape="round" type="primary" onClick={this.handleSave} style={{ position: "absolute", right: 0, fontWeight: 'bold', boxShadow: '5px 5px 5px #ccc' }}>
                                    <SaveOutlined /> SAVE
                                </Button>
                            </Col>                           
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(SocialMedia);