import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Card, Input, InputNumber, Row, Col, Typography, Select, Button, DatePicker, Table, Collapse, Radio, Space, Modal, Checkbox, Spin } from 'antd';
import { SearchOutlined, SaveOutlined, DeleteOutlined, PlusCircleOutlined, StarFilled, PercentageOutlined } from '@ant-design/icons';
import moment from 'moment';
const { Search } = Input;
const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Text } = Typography;


class EMSoldQuoteLog extends Component {
    constructor(props) {
        super(props);
          this.state = {
            effectiveDate: moment(this.props.effectiveDate).format("MM/DD/YYYY"),
            bindDate: moment(this.props.bindDate).format("MM/DD/YYYY"),
            emProductSoldCheckboxes: this.props.emProductSoldCheckboxes,
            emProductCheckboxes: this.props.emProductChkbx,
            originalStatus: this.props.originalStatus,
            soldBundle: this.props.soldBundle,
            autoBundle: false
          }
          this.handleChange = this.handleChange.bind(this);
          this.handleNumberChange = this.handleNumberChange.bind(this);
          this.handleBindDateChange = this.handleBindDateChange.bind(this);
          this.handleEffectiveDateChange = this.handleEffectiveDateChange.bind(this);
          this.disabledDate = this.disabledDate.bind(this);
          this.handleBundleChange = this.handleBundleChange.bind(this);
    }
    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        this.props.getLeadSource(agencyId);
        this.props.getTypeVendorTypes(agencyId);
        this.props.onSoldChange('soldBindDate', this.state.bindDate);
        this.props.onSoldChange('soldEffectiveDate', this.state.effectiveDate);
        var checkedEMProduct = this.state.emProductCheckboxes.filter(s => s.checked);
        console.log(this.state.emProductCheckboxes);
        var checkedSoldEMProduct = this.state.emProductSoldCheckboxes.filter(s => s.checked);
        if(this.state.originalStatus != 'SOLD') {
            if(checkedEMProduct.length > checkedSoldEMProduct.length){
                this.setState({emProductSoldCheckboxes: this.state.emProductCheckboxes});
                if(checkedSoldEMProduct.length > 0){
                    const data = checkedEMProduct;
                    const updatedData = data.map(group => {
                        if (checkedSoldEMProduct.filter(d => d.emProductId == group.emProductId).length > 0) {
                            const emProduct = checkedSoldEMProduct.filter(d => d.emProductId == group.emProductId);
                            console.log(emProduct[0]);
                            return {
                            ...group,
                            premium: emProduct[0].premium
                            };
                        } else {
                            return group;
                        }
                    });
                    this.setState({emProductSoldCheckboxes: updatedData});
                    this.props.onEMProductSoldChange(updatedData);
                }
            }
        }
    }
    componentDidUpdate(prevProps){
        if(this.props.typeVendorTypes != prevProps.typeVendorTypes){
            console.log("typeVendorTypeId: " + this.props.typeVendorTypeId);
            const autoBundle = this.props.typeVendorTypeId == false ? false : this.props.typeVendorTypes.find(vt => vt.id == this.props.typeVendorTypeId).autoBundle;
            console.log("AutoBundle: " + autoBundle);
            if(autoBundle == true){
                this.setState({soldBundle: autoBundle})
            }
        }
    }
    handleBindDateChange(date, dateString) {
        this.setState({ bindDate: date == null ? moment().format("MM/DD/YYYY") : date});
        this.props.onSoldChange('soldEMBindDate', date);
    }
    handleEffectiveDateChange(date, dateString) {
        this.setState({ effectiveDate: date == null ? moment().format("MM/DD/YYYY") : date});
        this.props.onSoldChange('soldEMEffectiveDate', date);
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        this.props.onSoldChange(e.target.name, e.target.value);
    }
    handleNumberChange = (e, name) => {
        this.setState({
            [name]: e
        })
        console.log(name + ":" + e)
        this.props.onSoldChange(name, e);
    }
    handleEMProductChange = (e) => {
        console.log(e);
        const data = this.state.emProductSoldCheckboxes;
        const { value, checked } = e.target;
        const updatedData = data.map(group => {
        if (group.emProductId === value) {
            return {
            ...group,
            checked: group.checked === checked ? null : checked,
            cnt: 1
            };
        } else {
            return group;
        }
        });
        this.setState({ emProductSoldCheckboxes: updatedData }, () => console.log(this.state.emProductSoldCheckboxes));
        this.props.onEMProductSoldChange(updatedData);
    }
    handleEMProductNumberChange = (e, id) => {
        console.log(e + " " + id);
        const data = this.state.emProductSoldCheckboxes;
        const updatedData = data.map(group => {
        if (group.emProductId === id) {
            return {
            ...group,
            cnt: e
            };
        } else {
            return group;
        }
        });
        this.setState({ emProductSoldCheckboxes: updatedData }, () => console.log(this.state.emProductSoldCheckboxes));
        this.props.onEMProductSoldChange(updatedData);
    }
    handleEMProductPremiumChange = (e, id) => {
        const data = this.state.emProductSoldCheckboxes;
        const updatedData = data.map(group => {
        if (group.emProductId === id) {
            return {
            ...group,
            premium: e.target.value
            };
        } else {
            return group;
        }
        });
        this.setState({ emProductSoldCheckboxes: updatedData }, () => console.log(this.state.emProductSoldCheckboxes));
        this.props.onEMProductSoldChange(updatedData);
    }
    
    disabledDate(current) {
        // Can not select days before today and today
        return current && current < moment(this.props.quoteDate);
    }
    handleBundleChange(e) {
        console.log(e.target.checked);
        this.setState({soldBundle: e.target.checked});
        this.props.onSoldChange(e.target.name, e.target.checked);
    }
    
    render(){
        return(
            <Row style={{border: 'solid 1px #97E45D', padding: '10px', marginTop: '10px'}}>
                <Col span={4} style={{paddingRight: 5}}>
                    <Row>
                        <Col>
                            <label htmlFor="bindDate">Bind Date<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                            <DatePicker 
                                disabledDate={this.disabledDate}
                                onChange={(e) => this.handleBindDateChange(e)} 
                                format={'MM/DD/YYYY'} style={{width: '100%'}} 
                                defaultValue={moment(this.state.bindDate, 'MM/DD/YYYY')} 
                                value={moment(this.state.bindDate, 'MM/DD/YYYY')} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label htmlFor="effectiveDate">Effective Date<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                            <DatePicker onChange={(e) => this.handleEffectiveDateChange(e)} format={'MM/DD/YYYY'} style={{width: '100%'}} defaultValue={moment(this.state.effectiveDate, 'MM/DD/YYYY')} value={moment(this.state.effectiveDate, 'MM/DD/YYYY')} />
                        </Col>
                    </Row>
                </Col>
                {this.state.emProductSoldCheckboxes.filter(s => s.checked && s.productType == "Dwelling").length > 0 &&
                <Col span={6} style={{paddingRight: 5}}>
                    <div>
                        <label htmlFor="state">Dwelling<small>(select all that apply)</small><span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                        {
                            this.state.emProductSoldCheckboxes.filter(s => s.checked && s.productType == "Dwelling").map(d => 
                                <Row key={d.emProductId}>
                                    <Col md={8}>
                                        <Checkbox value={d.emProductId} onChange={(e) => this.handleEMProductChange(e)} checked={d.checked}>{d.name}</Checkbox>
                                    </Col>
                                    <Col md={8}>
                                        {d.checked && 
                                            <InputNumber type="number" min={0} max={15} step={1} style={{width: '100%'}} value={d.cnt} onChange={e => this.handleEMProductNumberChange(e, d.emProductId)} />
                                        }
                                    </Col>
                                    <Col md={8}>
                                        {d.checked && 
                                            <Input type="number" formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')} name='premium' onChange={e => this.handleEMProductPremiumChange(e, d.emProductId)} placeholder="Premium" value={d.premium}  />
                                        }
                                    </Col>
                                </Row>
                            )
                        }
                    </div>
                </Col>
                }
                {this.state.emProductSoldCheckboxes.filter(s => s.checked && s.productType == "Auto").length > 0 &&
                <Col span={5} style={{paddingRight: 5}}>
                    <label htmlFor="state">Auto<small>(select all that apply)</small><span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                    {
                        this.state.emProductSoldCheckboxes.filter(s => s.checked && s.productType == "Auto").map(d =>
                            <Row key={d.emProductId}>
                                <Col md={8}>
                                    <Checkbox value={d.emProductId} onChange={(e) => this.handleEMProductChange(e)} checked={d.checked}>{d.name}</Checkbox>
                                </Col>
                                <Col md={8}>
                                    {d.checked && 
                                        <InputNumber type="number" min={0} max={15} step={1} style={{width: '100%'}} value={d.cnt} onChange={e => this.handleEMProductNumberChange(e, d.emProductId)} />
                                    }
                                </Col>
                                <Col md={8}>
                                    {d.checked && 
                                        <Input type="number" formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')} name='premium' onChange={e => this.handleEMProductPremiumChange(e, d.emProductId)} placeholder="Premium" value={d.premium}  />
                                    }
                                </Col>
                            </Row>
                        )
                    }
                </Col>
                }
                {this.state.emProductSoldCheckboxes.filter(s => s.checked && s.productType == "Specialty").length > 0 &&
                <Col span={6} style={{paddingRight: 5}}>
                    <div>
                        <label htmlFor="specialty">Specialty<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                        {
                            this.state.emProductSoldCheckboxes.filter(s => s.checked && s.productType == "Specialty").map(d => 
                                <Row key={d.emProductId}>
                                    <Col md={8}>
                                        <Checkbox value={d.emProductId} onChange={(e) => this.handleEMProductChange(e)} checked={d.checked}>{d.name}</Checkbox>
                                    </Col>
                                    <Col md={8}>
                                        {d.checked &&
                                        <InputNumber type="number" min={0} max={15} step={1} style={{width: '100%'}} value={d.cnt} onChange={e => this.handleEMProductNumberChange(e, d.emProductId)} />
                                        }
                                    </Col>
                                    <Col md={8}>
                                        {d.checked && 
                                            <Input type="number" formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} name='premium' onChange={e => this.handleEMProductPremiumChange(e, d.emProductId)} placeholder="Premium" value={d.premium}  />
                                        }
                                    </Col>
                                </Row>
                            )
                        }
                    </div>
                </Col>
                }
                {this.state.emProductSoldCheckboxes.filter(s => s.checked).length == 1 &&
                <Col md={3}>
                    <label htmlFor="soldBundle"></label><br />
                    <Checkbox checked={this.state.soldBundle} name="soldBundle" onChange={(e) => this.handleBundleChange(e)}>Bundle?</Checkbox>
                </Col>
                }
            </Row>
        )
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(EMSoldQuoteLog);