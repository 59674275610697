import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Row, Col, InputNumber, Typography, Select, Button, List, Input, Cascader, DatePicker, Radio } from 'antd'
import { SaveOutlined } from '@ant-design/icons';
import moment from 'moment'
const { Title, Text } = Typography;
const {Option} = Select;


class AgedLead extends Component {
    constructor(props) {
      super(props);
        this.state = {
            agedLeadMonthlyCount: 0,
            agedLeadTransferRatio: 0,
            agedLeadQuoteRatio: 0,
            actualAgedLeadMonthlyCount: 0,
            actualAgedLeadTransferRatio: 0,
            actualAgedLeadQuoteRatio: 0,
            agedLeadCloseRatio: 0,
            agedLeadPerHousehold: 0,
            agedLeadTransferRatioCalc: 0,
            agedLeadQuoteRatioCalc: 0,
            agedLeadCloseRatioCalc: 0,
            agedLeadTotalPremium: 0,
            agedLeadCampaignCount: 0,
            agedLeadMonthlyCost: 0,
            agedLeadMonthlyCostCalc: 0,
            agedLeadCostPerItems: 0,
            name: '',
            selectedLeadSourceId: '',
            monthDts: moment().format("MMM YYYY"),
            leadSourceFilter: 'Active',
        };
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
    }
    componentDidMount() {
        const userId = localStorage.getItem('userId');
        const agencyId = localStorage.getItem('agencyId');
        this.props.getTypeVendorTypeLeadSources(agencyId);
        this.props.getAgedLead(agencyId, moment().format("MMM YYYY"));
        this.props.getLeadSource(agencyId);
    }
    componentDidUpdate(prevProps){
        if(prevProps.agedLead != this.props.agedLead){
            this.props.onAgedLeadTotal();
        }
    }
    handleVendorChange = (e) => {
        var vendor = this.props.vendors.find(v => v.id == e)
        const agedLeadTransferRatio = vendor.rate;
        const agedLeadCloseRatio = vendor.closeRatio;
        const agedLeadQuoteRatio = vendor.quoteRatio;
        const selectedVendorId = vendor.id;
        this.setState({
            vendor: e,
            selectedVendorId: selectedVendorId,
            agedLeadTransferRatio: agedLeadTransferRatio,
            agedLeadCloseRatio: agedLeadCloseRatio,
            agedLeadQuoteRatio: agedLeadQuoteRatio
        });
    }
    onagedLeadMonthlyCountChange = value => {
        this.setState({
            agedLeadMonthlyCount: value
        })
    }
    onagedLeadTransferRatioChange = value => {
        this.setState({
            agedLeadTransferRatio: value
        });
    }
    onagedLeadQuoteRatioChange = value => {
        this.setState({
            agedLeadQuoteRatio: value
        });
    }
    onactualAgedLeadMonthlyCountChange = value => {
        this.setState({
            actualAgedLeadMonthlyCount: value
        })
    }
    onactualAgedLeadTransferRatioChange = value => {
        this.setState({
            actualAgedLeadTransferRatio: value
        });
    }
    onactualAgedLeadQuoteRatioChange = value => {
        this.setState({
            actualAgedLeadQuoteRatio: value
        });
    }
    onagedLeadCloseRatioChange = value => {
        this.setState({
            agedLeadCloseRatio: value
        });
    }
    onagedLeadCampaignCountChange = value => {
        this.setState({
            agedLeadCampaignCount: value
        });
    }
    onagedLeadMonthlyCostChange = value => {
        this.setState({
            agedLeadMonthlyCost: value
        });
    }
    onactualAgedLeadMonthlyCostChange = value => {
        this.setState({
            actualAgedLeadMonthlyCost: value
        });
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    onMonthChange(date, dateString) {
        const agencyId = localStorage.getItem("agencyId");
        if(dateString != ''){
            const dateTo = moment(date).endOf('month').format("MM/DD/YYYY");
            const dateFrom = moment(date).startOf('month').format("MM/DD/YYYY");
            this.props.getVendorSummary(agencyId, dateFrom, dateTo, '');
            this.setState({
                monthDts: dateString
            });
            this.props.getAgedLead(agencyId, moment(date).format("MMM YYYY"));
        }
        else{
            this.setState({
                monthDts: moment().format("MMM YYYY")
            });
        } 
    };
    handleListClick = (agedLead) => {
        var leadSource = this.props.vendorSummary.filter(v => v.typeQuoteLogLeadSourceId == agedLead.typeQuoteLogLeadSourceId);
        var leadSourceType = this.props.typeVendorTypesLeadSources.filter(c => c.label == leadSource[0].vendorType);
        var selectedValues = agedLead.typeQuoteLogLeadSourceId;
        this.setState({
            id: agedLead.id,
            name: agedLead.name,
            selectedValues: selectedValues,
            selectedLeadSourceId: agedLead.typeQuoteLogLeadSourceId,
            vendor: String(agedLead.vendorId),
            agedLeadMonthlyCount: agedLead.monthlyCount, 
            agedLeadTransferRatio: agedLead.transferRatio, 
            agedLeadQuoteRatio: agedLead.quoteRatio, 
            agedLeadCloseRatio: agedLead.closeRatio, 
            agedLeadCampaignCount: agedLead.leadCount, 
            agedLeadMonthlyCost: agedLead.monthlyCost,
            actualAgedLeadMonthlyCount: agedLead.actualMonthlyCount, 
            actualAgedLeadTransferRatio: agedLead.actualTransferRatio, 
            actualAgedLeadQuoteRatio: agedLead.actualQuoteRatio,
            actualAgedLeadMonthlyCost: agedLead.actualMonthlyCost
        });
    }
    handleSave(e) {
        e.preventDefault()
        const userId = localStorage.getItem("userId");
        const agencyId = localStorage.getItem("agencyId");
        const typeQuoteLogLeadSourceId = this.state.selectedLeadSourceId;
        const vendorId = 0;
        const name = this.state.name;
        const monthlyCount = this.state.agedLeadMonthlyCount;
        const transferRate = this.state.agedLeadTransferRatio;
        const quoteRatio = this.state.agedLeadQuoteRatio;
        const closeRatio = this.state.agedLeadCloseRatio;
        const agedLeadCampaignCount = this.state.agedLeadCampaignCount;
        const agedLeadMonthlyCost = this.state.agedLeadMonthlyCost;
        const premiumPerItem = this.props.premiumPerItem;

        const actualMonthlyCount = this.state.actualAgedLeadMonthlyCount;
        const actualTransferRate = this.state.actualAgedLeadTransferRatio;
        const actualQuoteRatio = this.state.actualAgedLeadQuoteRatio;
        const actualAgedLeadMonthlyCost = this.state.actualAgedLeadMonthlyCost;

        const agedLeadQuoteRatioCalc = this.state.agedLeadMonthlyCount * (this.state.agedLeadQuoteRatio / 100);

        const agedLeadQuotedItems = this.props.itemsPerHousehold * agedLeadQuoteRatioCalc;
        const agedLeadPerHousehold = agedLeadQuotedItems / this.props.itemsPerHousehold;
        const agedLeadQuotedPolicies = agedLeadQuotedItems / this.props.itemsPerPolicy;
        
        const agedLeadSoldItems = agedLeadQuotedItems * (this.state.agedLeadCloseRatio / 100);
        const agedLeadSoldHouseholds = agedLeadPerHousehold * (this.state.agedLeadCloseRatio / 100);
        const agedLeadSoldPolicies = agedLeadQuotedPolicies * (this.state.agedLeadCloseRatio / 100);
        const monthDts = this.state.monthDts;

        const agedLeadTotalPremium = agedLeadSoldItems * this.props.premiumPerItem;

        const filterLeadSource = this.props.vendorSummary ? this.props.vendorSummary.filter(v => v.typeQuoteLogLeadSourceId == this.state.selectedLeadSourceId) : [];
        const actualAgedLeadQuotedItems = filterLeadSource.length > 0 ? filterLeadSource[0].quotedItems : 0;
        const actualAgedLeadPerHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].quotedHouseholds : 0;
        const actualAgedLeadQuotedPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].quotedPolicies : 0;

        const actualAgedLeadSoldItems = filterLeadSource.length > 0 ? filterLeadSource[0].writtenItems : 0;
        const actualAgedLeadSoldHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].writtenHouseholds : 0;
        const actualAgedLeadSoldPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].writtenPolicies : 0;

        const actualAgedLeadMonthlyCostCalc = this.state.actualAgedLeadMonthlyCost * 1;
        const actualAgedLeadMonthlyCostWrittenItems = actualAgedLeadMonthlyCostCalc / actualAgedLeadSoldItems;
        const actualAgedLeadMonthlyHousehold = actualAgedLeadMonthlyCostCalc / actualAgedLeadSoldHousehold;
        const actualAgedLeadMonthlyPolicy = actualAgedLeadMonthlyCostCalc / actualAgedLeadSoldPolicies;

        const agedLeadMonthlyCostCalc = this.state.agedLeadMonthlyCost * 1;
        const agedLeadMonthlyCostWrittenItems = agedLeadMonthlyCostCalc / agedLeadSoldItems;
        const agedLeadMonthlyHousehold = agedLeadMonthlyCostCalc / agedLeadSoldHouseholds;
        const agedLeadMonthlyPolicy = agedLeadMonthlyCostCalc / agedLeadSoldPolicies;

        this.props.createAgedLead(
            agencyId,
            typeQuoteLogLeadSourceId,
            userId, 
            name, 
            vendorId, 
            monthlyCount, 
            transferRate, 
            quoteRatio, 
            closeRatio,
            agedLeadMonthlyCost,
            actualMonthlyCount,
            actualTransferRate, 
            actualQuoteRatio,
            actualAgedLeadMonthlyCost,
            agedLeadPerHousehold, 
            agedLeadQuotedPolicies, 
            agedLeadQuotedItems, 
            agedLeadSoldHouseholds, 
            agedLeadSoldPolicies, 
            agedLeadSoldItems, 
            monthDts, 
            agedLeadTotalPremium,
            premiumPerItem,
            agedLeadMonthlyCostWrittenItems, 
            agedLeadMonthlyHousehold,
            agedLeadMonthlyPolicy,
            actualAgedLeadMonthlyCostWrittenItems, 
            actualAgedLeadMonthlyHousehold, 
            actualAgedLeadMonthlyPolicy);

        this.setState({
            name: '', 
            agedLeadMonthlyCount: '', 
            agedLeadTransferRatio: '', 
            agedLeadQuoteRatio: '', 
            agedLeadCloseRatio: '', 
            agedLeadMonthlyTransferCount: '', 
            agedLeadMonthlyCost: '',
            selectedValues: ''
        });
    }
    handleRadioChange(e) {
        this.setState({leadSourceFilter: e.target.value});
    }
    render() {
        const agedLeadTransferRatioCalc = this.state.agedLeadMonthlyCount * (this.state.agedLeadTransferRatio / 100);
        const agedLeadQuoteRatioCalc = agedLeadTransferRatioCalc * (this.state.agedLeadQuoteRatio / 100);
        const agedLeadCloseRatioCalc = agedLeadQuoteRatioCalc * (this.state.agedLeadCloseRatio / 100);

        const agedLeadQuotedItems = this.props.itemsPerHousehold * agedLeadQuoteRatioCalc;
        const agedLeadPerHousehold = agedLeadQuotedItems / this.props.itemsPerHousehold;
        const agedLeadQuotedPolicies = agedLeadQuotedItems / this.props.itemsPerPolicy;

        const agedLeadTotalPremium = agedLeadPerHousehold * this.props.premiumPerItem;
        
        const agedLeadSoldItems = agedLeadQuotedItems * (this.state.agedLeadCloseRatio / 100);
        const agedLeadSoldHousehold = agedLeadPerHousehold * (this.state.agedLeadCloseRatio / 100);
        const agedLeadSoldPolicies = agedLeadQuotedPolicies * (this.state.agedLeadCloseRatio / 100);

        const filterLeadSource = this.props.vendorSummary ? this.props.vendorSummary.filter(v => v.typeQuoteLogLeadSourceId == this.state.selectedLeadSourceId) : [];
        const actualAgedLeadQuotedItems = filterLeadSource.length > 0 ? filterLeadSource[0].quotedItems : 0;
        const actualAgedLeadPerHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].quotedHouseholds : 0;
        const actualAgedLeadQuotedPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].quotedPolicies : 0;

        const actualAgedLeadSoldItems = filterLeadSource.length > 0 ? filterLeadSource[0].writtenItems : 0;
        const actualAgedLeadSoldHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].writtenHouseholds : 0;
        const actualAgedLeadSoldPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].writtenPolicies : 0;

        const actualAgedLeadCloseRatio = actualAgedLeadSoldHousehold > 0 ? actualAgedLeadSoldHousehold / actualAgedLeadPerHousehold : 0;
        const actualAgedLeadTotalPremium = filterLeadSource.length > 0 ? filterLeadSource[0].writtenPremium : 0
        const actualPremiumPerItem = actualAgedLeadTotalPremium > 0 ? actualAgedLeadTotalPremium / actualAgedLeadSoldItems : 0;
        
        const agedLeadMonthlyCostCalc = this.state.agedLeadMonthlyCost * 1;
        const agedLeadCostPerItems = agedLeadMonthlyCostCalc / agedLeadSoldHousehold;
        const agedLeadMonthlyCostWrittenItems = agedLeadMonthlyCostCalc / agedLeadSoldItems;
        const agedLeadMonthlyHousehold = agedLeadMonthlyCostCalc / agedLeadPerHousehold;
        const agedLeadMonthlyPolicy = agedLeadMonthlyCostCalc / agedLeadSoldPolicies;

        const actualAgedLeadMonthlyCostCalc = this.state.actualAgedLeadMonthlyCost * 1;
        const actualAgedLeadCostPerItems = actualAgedLeadMonthlyCostCalc / actualAgedLeadSoldItems;
        const actualAgedLeadMonthlyHousehold = actualAgedLeadMonthlyCostCalc / actualAgedLeadSoldHousehold;
        const actualAgedLeadMonthlyPolicy = actualAgedLeadMonthlyCostCalc / actualAgedLeadSoldPolicies;

        const agedLeadCostPerQuotedHousehold = agedLeadMonthlyCostCalc / agedLeadPerHousehold;
        const actualAgedLeadCostPerQuotedHousehold = actualAgedLeadMonthlyCostCalc / actualAgedLeadPerHousehold;

        const onChange = (value) => {
            var agedLeadLst = this.props.agedLead.filter(t => t.typeQuoteLogLeadSourceId == value);
            if(agedLeadLst.length > 0){
                var agedLead = agedLeadLst[0];
                this.setState({
                    id: agedLead.id,
                    name: agedLead.name,
                    selectedValues: value,
                    selectedLeadSourceId: agedLead.typeQuoteLogLeadSourceId,
                    vendor: String(agedLead.vendorId),
                    agedLeadMonthlyCount: agedLead.monthlyCount, 
                    agedLeadTransferRatio: agedLead.transferRatio, 
                    agedLeadQuoteRatio: agedLead.quoteRatio, 
                    agedLeadCloseRatio: agedLead.closeRatio, 
                    agedLeadCampaignCount: agedLead.leadCount, 
                    agedLeadMonthlyCost: agedLead.monthlyCost,
                    actualAgedLeadMonthlyCount: agedLead.actualMonthlyCount, 
                    actualAgedLeadTransferRatio: agedLead.actualTransferRatio, 
                    actualAgedLeadQuoteRatio: agedLead.actualQuoteRatio,
                    actualAgedLeadMonthlyCost: agedLead.actualMonthlyCost
                });
            }
            else{
                console.log(value);
                this.setState({
                    id: 0,
                    name: '',
                    selectedValues: value,
                    selectedLeadSourceId: value, 
                    agedLeadMonthlyCount: 0,
                    agedLeadResponseRate: 0,
                    agedLeadQuoteRatio: 0,
                    agedLeadCloseRatio: 0,
                    agedLeadPerHousehold: 0,
                    agedLeadResponseRateCalc: 0,
                    agedLeadQuoteRatioCalc: 0,
                    agedLeadCloseRatioCalc: 0,
                    agedLeadTotalPremium: 0,
                    agedLeadMonthlyCost: 0,
                    agedLeadMonthlyCostCalc: 0,
                    agedLeadCostWrittenItems: 0,
                    actualAgedLeadMonthlyCount: 0,
                    actualAgedLeadResponseRate: 0,
                    actualAgedLeadQuoteRatio: 0,
                    actualAgedLeadMonthlyCost: 0
                });
            }
        }
            

        const styles = {
            disabledInputText: {
                width: '100%', 
                marginLeft: 10, 
                color: '#000'
            },
        };
        return(
            <div>
                <Row>
                    <Col span={10}>
                        <List header={<Title level={4}>LEAD SOURCE</Title>} itemLayout="horizontal" dataSource={this.props.agedLead} renderItem={item => (
                            <List.Item actions={[<a key="list-loadmore-edit" onClick={() => this.handleListClick(item)}>edit</a>]}>
                                <List.Item.Meta title={item.name} />
                            </List.Item>
                        )} /> 
                    </Col>
                    <Col span={14}>
                    <Row style={{paddingBottom: 15}}>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Month
                                </div>
                            </Col>
                            <Col md={16}>
                                <DatePicker style={{ width: '100%', marginLeft: 10 }} onChange={this.onMonthChange} picker="month" format="MMM YYYY" value={moment(this.state.monthDts, "MMM YYYY")}   />
                            </Col>
                        </Row>
                        <Row style={{paddingBottom: 15}}>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0 }}>
                                    Lead Source
                                </div>
                            </Col>
                            <Col md={16}>
                                <Radio.Group style={{marginLeft: 10}} size="small" defaultValue="Active" buttonStyle="solid" onChange={this.handleRadioChange} value={this.state.leadSourceFilter}>
                                    <Radio.Button value="Active">Active</Radio.Button>
                                    <Radio.Button value="Inactive">Inactive</Radio.Button>
                                </Radio.Group>
                                <Select
                                    virtual={false}
                                    style={{ width: '100%', marginLeft: 10 }}
                                    dropdownStyle={{ minWidth: "20%" }}
                                    placeholder="Please select"
                                    name="leadSource"
                                    value={this.state.selectedValues}
                                    onChange={onChange}>
                                    {
                                        this.state.leadSourceFilter == "Active" ?
                                        this.props.leadSource.filter(ls => ls.inactive == false).map(ls => 
                                            <Option key={ls.id} value={ls.id}>{ls.name}</Option>
                                        )
                                        :
                                        this.props.leadSource.filter(ls => ls.inactive == true).map(ls => 
                                            <Option key={ls.id} value={ls.id}>{ls.name}</Option>
                                        )
                                    }
                                </Select>
                                {/* <Cascader
                                    style={{ width: '100%', marginLeft: 10 }}
                                    options={this.props.typeVendorTypesLeadSources.filter(c => c.children.length > 0)}
                                    onChange={onChange}
                                    maxTagCount="responsive"
                                    placeholder="Select Lead Source"
                                    value={this.state.selectedValues}
                                /> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                                          
                            </Col>
                            <Col md={8} style={{textAlign: 'center', fontWeight: 'bold'}}>
                                FORECASTED
                            </Col>
                            <Col md={8} style={{textAlign: 'center', fontWeight: 'bold'}}>
                                ACTUALS
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Monthly Data Lead Count
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.agedLeadMonthlyCount} onChange={this.onagedLeadMonthlyCountChange} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.actualAgedLeadMonthlyCount} onChange={this.onactualAgedLeadMonthlyCountChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Contact Rate
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={.1} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.agedLeadTransferRatio} onChange={this.onagedLeadTransferRatioChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={.1} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.actualAgedLeadTransferRatio} onChange={this.onactualAgedLeadTransferRatioChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Quote Ratio
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.agedLeadQuoteRatio} onChange={this.onagedLeadQuoteRatioChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.actualAgedLeadQuoteRatio} onChange={this.onactualAgedLeadQuoteRatioChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Close Ratio
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.agedLeadCloseRatio} onChange={this.onagedLeadCloseRatioChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={new Intl.NumberFormat("en-US", {
                                    style: "percent",
                                    maximumFractionDigits: 2
                                    }).format(actualAgedLeadCloseRatio)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Premium Per Item
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber disabled style={styles.disabledInputText} value={this.props.premiumPerItem} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}  />
                            </Col>
                            <Col md={8}>
                                <InputNumber disabled style={styles.disabledInputText} value={actualPremiumPerItem.toFixed(2)} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Quoted Households
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={agedLeadPerHousehold.toFixed(2)} name="quotedHouseholds" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualAgedLeadPerHousehold} name="quotedHouseholds" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Quoted Polices
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={agedLeadQuotedPolicies.toFixed(2)} name="quotedPolicies" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualAgedLeadQuotedPolicies} name="quotedPolicies" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Quoted Items
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={agedLeadQuotedItems.toFixed(2)} name="quotedItems" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualAgedLeadQuotedItems} name="quotedItems" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Sold Households
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={agedLeadSoldHousehold.toFixed(2)} name="soldHouseholds" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualAgedLeadSoldHousehold} name="soldHouseholds" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Sold Polices
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={agedLeadSoldPolicies.toFixed(2)} name="soldPolicies" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualAgedLeadSoldPolicies} name="soldPolicies" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Sold Items
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={agedLeadSoldItems.toFixed(2)} name="soldItems" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualAgedLeadSoldItems} name="soldItems" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                TOTAL WRITTEN PREMIUM
                            </Col>
                            <Col md={8}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(agedLeadTotalPremium)}</Text>
                            </Col>
                            <Col md={8}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualAgedLeadTotalPremium)}</Text>
                            </Col>
                        </Row>
                        <Row md={24} style={{backgroundColor: "#F5D5CB", fontWeight: 'bold' }}>
                            AGED LEAD ACQUISITION COST
                        </Row>
                        <Row>
                            <Col md={8}>
                                Aged Lead Monthly Cost
                            </Col>
                            <Col md={8}>
                                <InputNumber min={.1} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.agedLeadMonthlyCost} onChange={this.onagedLeadMonthlyCostChange} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={.1} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.actualAgedLeadMonthlyCost} onChange={this.onactualAgedLeadMonthlyCostChange} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                Aged Lead Cost Per Quoted Household
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(agedLeadCostPerQuotedHousehold)}</Text>
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualAgedLeadCostPerQuotedHousehold)}</Text>
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                Aged Lead Item Acquistion Cost 
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(agedLeadMonthlyCostWrittenItems)}</Text>
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualAgedLeadCostPerItems)}</Text>
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                Age Lead Policy Acquistion Cost 
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(agedLeadMonthlyPolicy)}</Text>
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualAgedLeadMonthlyPolicy)}</Text>
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                Age Lead Household Acquistion Cost 
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}> {new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(agedLeadMonthlyHousehold)}</Text>
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualAgedLeadMonthlyHousehold)}</Text>
                            </Col>
                        </Row>
                        <Row style={{paddingBottom: 10}}>
                            <Col span={24} style={{padding: 16}}>
                                <Button shape="round" type="primary" onClick={this.handleSave} style={{ position: "absolute", right: 0, fontWeight: 'bold', boxShadow: '5px 5px 5px #ccc' }}>
                                    <SaveOutlined /> SAVE
                                </Button>
                            </Col>                           
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(AgedLead);