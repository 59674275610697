import React, { Component  } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Card, Input, InputNumber, Row, Col, Typography, Select, Button, DatePicker, Table, Collapse, Radio, Space, Modal, Checkbox, Spin, Tooltip } from 'antd';
import { SearchOutlined, DownloadOutlined, CheckOutlined, FunnelPlotOutlined, ArrowLeftOutlined, ArrowRightOutlined, TableOutlined, CopyOutlined } from '@ant-design/icons';
import moment from 'moment';
import SoldQuoteLog from './SoldQuoteLog';
import ActivitySummary from './ActivitySummary';
import QuoteLogEntry from './QuoteLogEntry';
import { IGraphCommandContribution } from '@antv/xflow-core';
import PremiumAverage from './PremiumAverage';
import Confetti from 'react-confetti';
import ConfettiExplosion from 'react-confetti-explosion';
const { Search } = Input;
const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Text } = Typography;


class QuoteLog extends Component {
    constructor(props) {
      super(props);
        this.state = {
            selectedColumns: [],
            selectedQuoteLog: [],
            tableSearch: [],
            filteredInfo: {},
        }
        
        this.handleTableClick = this.handleTableClick.bind(this);
        this.exportToCSV = this.exportToCSV.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.handleEntryVisibleChange = this.handleEntryVisibleChange.bind(this);
    }
    
    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        const dateFrom = this.props.dateFrom; //moment().startOf('month').format("MM/DD/YYYY");
        const dateTo = this.props.dateTo; //moment().endOf('month').format("MM/DD/YYYY");
        this.props.getQuoteLogReport(agencyId, dateFrom, dateTo, "All");
        const selectedColumns = localStorage.getItem('selectedColumns');
        const selectedColumnsArray = selectedColumns != null ? selectedColumns.split(',') : [];
        // const filteredColumn = this.props.filteredColumn;
        // const filteredValue = this.props.filteredValue;
        // const filteredInfo = {[filteredColumn]: [filteredValue]};
        // console.log(filteredInfo);
        this.setState({selectedColumns: selectedColumns == null || selectedColumns == "" ? [] : selectedColumnsArray});

        console.log(this.state.filteredInfo);
    }
    componentDidUpdate(prevProps){
        if(this.props.dateFrom != prevProps.dateFrom || this.props.dateTo != prevProps.dateTo){
            // const filteredColumn = this.props.filteredColumn;
            // const filteredValue = this.props.filteredValue;
            // const filteredInfo = {[filteredColumn]: [filteredValue]};
            // console.log(filteredInfo);
            // this.setState({filteredInfo: filteredInfo});
            const agencyId = localStorage.getItem('agencyId');
            const dateFrom = this.props.dateFrom; //moment().startOf('month').format("MM/DD/YYYY");
            const dateTo = this.props.dateTo; //moment().endOf('month').format("MM/DD/YYYY");
            this.props.getQuoteLogReport(agencyId, dateFrom, dateTo, "All");
        }
        // if(this.props.filteredValue != prevProps.filteredValue){
        //     const filteredColumn = this.props.filteredColumn;
        //     const filteredValue = this.props.filteredValue;
        //     const filteredInfo = {[filteredColumn]: [filteredValue]};
        //     console.log(filteredInfo);
        //     this.setState({filteredInfo: filteredInfo});
        // }
    }
    handleEntryVisibleChange = (status) => {
        if(status == "SOLD"){
            this.setState({showConfetti: true});
            setTimeout(() => {
                this.setState({ text: "show confetti", recycleConfetti: false });
            }, 5000);
        }
        this.setState({ visible: !this.state.visible });
        const seed = Math.random();
        console.log("Seed: " + seed);
        if(this.state.visible == false){
            this.setState({selectedQuoteLog: [], seed: seed, showConfetti: false, recycleConfetti: true});
        }
    }
    handleTableClick = (quoteLog) => {
        const agencyId = localStorage.getItem('agencyId');
        this.setState({
            selectedQuoteLog: quoteLog,
            visible: true,
            soldAuto: quoteLog.auto
        });
        
    }
    handleTableSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        if(selectedKeys[0] == undefined) {
            selectedKeys[0] = '';
        }
        console.log("Search: " + selectedKeys[0]);
        const tableSearch = this.state.tableSearch;
        let searchData = { searchIndex: dataIndex, searchText: selectedKeys[0] };
        const i = tableSearch.findIndex(e => e.searchIndex === dataIndex);
        if (i > -1) {
            tableSearch[i] = searchData;
        } else {
            tableSearch.push(searchData);
        }
        console.log(tableSearch);
        this.setState({
            tableSearch: tableSearch,
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
      };
    handleReset = clearFilters => {
        clearFilters();
        //this.setState({ searchText: '' });
    };
   
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleTableSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleTableSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#97E45D' : undefined }} />,
        onFilter: (value, record) =>
        record != null &&
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
    });
    
    exportToCSV = (columns) => {
        //e.preventDefault();
        console.log(columns);
        var data, filename, link;
        var csv = this.convertArrayOfObjectsToCSV(this.props.quoteLog, columns);
        if (csv == null)
          return;
    
        filename = 'QuoteLog' + this.state.dateFrom + '-' + this.state.dateTo + '.csv';
    
        var blob = new Blob([csv], {type: "text/csv;charset=utf-8;"});
    
        if (navigator.msSaveBlob)
        { // IE 10+
          navigator.msSaveBlob(blob, filename)
        }
        else
        {
          var link = document.createElement("a");
          if (link.download !== undefined)
          {
            // feature detection, Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style = "visibility:hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
    
        //console.log(arrOrders);
      }
    
      convertArrayOfObjectsToCSV(args, columns) {
        var result, ctr, keys, columnDelimiter, lineDelimiter, data;
    
        data = args || null;
        if (data == null || !data.length) {
            return null;
        }
    
        columnDelimiter = args.columnDelimiter || ',';
        lineDelimiter = args.lineDelimiter || '\n';
    
        keys = Object.keys(data[0]);
    
        result = '';
        //result += keys.join(columnDelimiter);
        keys.forEach(function(key) {
            var column = columns.find(c => c.key == key);
            console.log(column);
            if(column != undefined){
                result += column.title;
                result += columnDelimiter;
            }
            else{
                result += key;
                result += columnDelimiter;
            }
        })
        result += lineDelimiter;
    
        data.forEach(function(item) {
            ctr = 0;
            keys.forEach(function(key) {
                //if(key != 'id' && key != 'agencyid' && key != 'createdDts' && key != 'typeQuoteLogLeadSourceId'){
                    if (ctr > 0) result += columnDelimiter;
                    if(key == 'notes' || key == 'objection'){
                        
                        var val = item[key].toString();
                        val = val.replace(/[\n\r]+/g, ' ');
                        val = val.replaceAll(',', ' ');
                        result += val;
                        
                    }
                    else{
                        result += item[key]
                    }
                    ctr++;
                //}
            });
            result += lineDelimiter;
        });
    
        return result;
    };
    
    copyToClipboard (text) {
        navigator.clipboard.writeText(text);
    
        console.log("Copied: " + text);
    }
    render() {
        const filterLeadSource = [];
        this.props.leadSource.map (ls =>
            filterLeadSource.push({
                text: ls.name,
                value: ls.name
            })
        );
        const filterAgent = [];
        this.props.agent.filter(a => a.status != "5").map (ls =>
            filterAgent.push({
                text: ls.firstName,
                value: ls.firstName
            })
        );
        const filterReferringAgent = [];
        filterReferringAgent.push({
            text: "",
            value: ""
        })
        this.props.referringAgent.map (ra =>
            filterReferringAgent.push({
                text: ra.name,
                value: ra.name
            })
        );
        const filterObjection = [];
        this.props.objection.map (o =>
            filterObjection.push({
                text: o.name,
                value: o.name
            })
        );
        const filterIneligible = [];
        this.props.ineligible.map (o =>
            filterIneligible.push({
                text: o.name,
                value: o.name
            })
        );
        filterReferringAgent.sort();
        const dateFormat = 'MM/DD/YYYY';
        var showFullNote = this.state.showFullNote;
        console.log(showFullNote);
        const columns = [
            {
                title: 'Quote Date',
                dataIndex: 'dts',
                key: 'dts',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.dts : [],
                render: date => moment(date).format(dateFormat),
            },
            {
                title: 'Bind Date',
                dataIndex: 'bindDate',
                key: 'bindDate',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.binddate : [],
                render: date => date != null ? moment(date).format(dateFormat) : '',
            },
            {
                title: 'Agent',
                dataIndex: 'agent',
                key: 'agent',
                filters: filterAgent,
                filterMultiple: true,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.agent : [],
                onFilter: (value, record) => record.agent.indexOf(value) === 0,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                filters: [
                    // {
                    //     text: 'SOLD',
                    //     value: 'SOLD',
                    // },
                    {
                        text: 'HOT',
                        value: 'HOT',
                    },
                    {
                        text: 'WARM',
                        value: 'WARM',
                    },
                    {
                        text: 'COLD',
                        value: 'COLD',
                    },
                    {
                        text: 'TERMINATED',
                        value: 'TERMINATED',
                    },
                  ],
                  filterMultiple: true,
                  filteredValue: this.state.filteredInfo ? this.state.filteredInfo.status : [],
                  //defaultFilteredValue: this.state.tableSearch.filter(t => t.searchIndex == 'status').length > 0 ? [this.state.tableSearch.filter(t => t.searchIndex == 'status')[0].searchText] : [],
                  onFilter: (value, record) => record.status.indexOf(value) === 0,
            },
            {
                title: 'First Name',
                dataIndex: 'firstName',
                key: 'firstName',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.firstName : [],
                //defaultFilteredValue: this.state.tableSearch.filter(t => t.searchIndex == 'firstName').length > 0 ? [this.state.tableSearch.filter(t => t.searchIndex == 'firstName')[0].searchText] : [],
                ...this.getColumnSearchProps('firstName'),
            },
            {
                title: 'Last Name',
                dataIndex: 'lastName',
                key: 'lastName',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.lastName : [],
                ...this.getColumnSearchProps('lastName'),
            },
            {
                title: 'Lead Source',
                dataIndex: 'leadSource',
                key: 'leadSource',
                render: (text, record) => this.props.leadSource.filter(l => l.id == text).length == 0 ? text : this.props.leadSource.find(l => l.id == text).name,
                //...this.getColumnSearchProps('leadSource'),
                filters: filterLeadSource,
                filterMultiple: true,
                filterSearch: true,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.leadSource : [],
                onFilter: (value, record) => record.leadSource.indexOf(value) === 0,
            },
            {
                title: 'Dwelling',
                dataIndex: 'dwelling',
                key: 'dwelling',
                width: '10%',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.dwelling : [],
                ...this.getColumnSearchProps('dwelling'),
            },
            {
                title: 'Auto',
                dataIndex: 'auto',
                key: 'auto',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.auto : [],
                ...this.getColumnSearchProps('auto'),
            },
            {
                title: 'Comm. Auto',
                dataIndex: 'commercialAuto',
                key: 'commercialAuto',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.commercialAuto : [],
                ...this.getColumnSearchProps('commercialAuto'),
            },
            {
                title: 'Specialty',
                dataIndex: 'specialty',
                key: 'specialty',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.specialty : [],
                ...this.getColumnSearchProps('specialty'),
            },
            {
                title: 'Objection',
                dataIndex: 'objection',
                key: 'objection',
                filters: filterObjection,
                filterMultiple: true,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.objection : [],
                onFilter: (value, record) => record.objection.indexOf(value) === 0,
                // filteredValue: this.state.filteredInfo ? this.state.filteredInfo.objection : [],
                // ...this.getColumnSearchProps('objection'),
            },
            {
                title: 'Ineligible',
                dataIndex: 'ineligible',
                key: 'ineligible',
                filters: filterIneligible,
                filterMultiple: true,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.ineligible : [],
                onFilter: (value, record) => record.ineligible.indexOf(value) === 0,
                // filteredValue: this.state.filteredInfo ? this.state.filteredInfo.ineligible : [],
                // ...this.getColumnSearchProps('ineligible'),
            },
            {
                title: 'Zip',
                dataIndex: 'zip',
                key: 'zip',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.zip : [],
            
                ...this.getColumnSearchProps('zip'),
            },
            {
                title: 'Notes',
                dataIndex: 'notes',
                key: 'notes',
                width: 500,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.notes : [],
                render: (text, record) => {
                return(
                    text.length > 80 && showFullNote != record.id ?
                    <div>
                        <Tooltip title="copy to clipboard" >
                            <Button type="primary" size="small" shape="circle" icon={<CopyOutlined />} style={{display: text.length == 0 ? 'none' : 'block'}} onClick={() => this.copyToClipboard(text)} />
                        </Tooltip>
                        <Text>{text.substring(0, 80) + "..."}</Text>
                        <Button type="link" onClick={() => this.setState({showFullNote: record.id})}>read more</Button>
                    </div>
                    :
                    <div>
                        <Tooltip title="copy to clipboard" >
                            <Button type="primary" size="small" shape="circle" icon={<CopyOutlined />} style={{display: text.length == 0 ? 'none' : 'block'}} onClick={() => this.copyToClipboard(text)} />
                        </Tooltip>
                        <Text>{text}</Text>
                        {text.length > 80 &&
                        <Button type="link" onClick={() => this.setState({showFullNote: ''})}>read less</Button>
                        }
                    </div>
                )
                // <div>{text.length > 80 && showFullNote == false && text.substring(0, 80) + "..."} {text.length > 80 && <Button type="link" onClick={() => this.setState({showFullNote: true})}>read more</Button>}  {text.length <= 80 && text}</div>
                },
                ...this.getColumnSearchProps('notes'),
            },
            {
                title: 'Bundle',
                dataIndex: 'bundle',
                key: 'bundle',
                filters: [
                    {
                        text: 'Yes',
                        value: true,
                    },
                    {
                        text: 'No',
                        value: false,
                    },
                ],
                filterMultiple: false,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.bundle : [],
                align: 'center',
                render: (text, record) => text == true ? <CheckOutlined style={{fontSize: '20px'}} /> : "",
                onFilter: (value, record) => record.bundle === value
            },
            {
                title: 'Self Gen Quotes',
                dataIndex: 'selfGenerated',
                key: 'selfGenerated',
                filters: [
                    {
                        text: 'Yes',
                        value: true,
                    },
                    {
                        text: 'No',
                        value: false,
                    },
                ],
                filterMultiple: false,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.selfGenerated : [],
                align: 'center',
                render: (text, record) => text == true ? <CheckOutlined style={{fontSize: '20px'}} /> : "",
                onFilter: (value, record) => record.selfGenerated === value
            },
            {
                title: 'Requote / Aged Lead',
                dataIndex: 'requote',
                key: 'requote',
                filters: [
                    {
                        text: 'Yes',
                        value: true,
                    },
                    {
                        text: 'No',
                        value: false,
                    },
                ],
                filterMultiple: false,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.requote : [],
                align: 'center',
                render: (text, record) => text == true ? <CheckOutlined style={{fontSize: '20px'}} /> : "",
                onFilter: (value, record) => record.requote === value
            },
            {
                title: 'Dwelling Ins Score',
                dataIndex: 'insuranceScore',
                key: 'insuranceScore',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.insuranceScore : [],
                ...this.getColumnSearchProps('insuranceScore'),
            },
            {
                title: 'Auto Ins Score',
                dataIndex: 'insuranceScoreAuto',
                key: 'insuranceScoreAuto',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.insuranceScore : [],
                ...this.getColumnSearchProps('insuranceScoreAuto'),
            },
            {
                title: 'Premium',
                dataIndex: 'totalPremium',
                key: 'totalPremium',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.premium : [],
                render: (text, record) => new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(text),
                ...this.getColumnSearchProps('totalPremium'),
            },
            {
                title: 'Referring Agent',
                dataIndex: 'referringAgentName',
                key: 'referringAgentName',
                filters: filterReferringAgent,
                filterMultiple: true,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.referringAgentName : [],
                //render: (text, record) => this.props.referringAgent && text != 0 ? this.props.referringAgent.find(ra => ra.id == text).name : "",
                onFilter: (value, record) => record.referringAgentName == value,
                
            },
            {
                title: '',
                key: 'action',
                render: (text, record) => <a onClick={() => this.handleTableClick(record)}>Edit</a>,
            },
        ];
        
        const filteredColumns = this.state.selectedColumns.length == 0 ? columns : columns.filter(c => this.state.selectedColumns.includes(c.title));
        const userId = localStorage.getItem('userId');
        const loggedInAgent = this.props.agent.filter(a => a.userId == userId);
        const loggedInAgentName = loggedInAgent.length == 0 ? '' : loggedInAgent[0].firstName;
        const loggedInAgentId = loggedInAgent.length == 0 ? '' : loggedInAgent[0].agentId;
        const onlySelf = loggedInAgent.length == 0 ? false : loggedInAgent[0].onlySelf
        console.log(this.props.filteredColumn + " - " + this.props.filteredValue);
        const handleChange = (pagination, filters, sorter) => {
            console.log(filters);
            this.setState({filteredInfo: filters});
            //setFilteredInfo(filters);
            //setSortedInfo(sorter);
        };
        const clearFilters = () => {
            console.log('clear filters');
            this.setState({filteredInfo: [], dateFilter: '', reportFilter: 'All', dateFrom: moment().startOf('month').format("MM/DD/YYYY"), dateTo: moment().endOf('month').format("MM/DD/YYYY")});
            const agencyId = localStorage.getItem('agencyId');
            this.props.getQuoteLogReport(agencyId, moment().startOf('month').format("MM/DD/YYYY"), moment().endOf('month').format("MM/DD/YYYY"), 'All');
        };
        const filteredQuoteLog = this.props.quoteLog.filter(q => q[this.props.filteredColumn] == this.props.filteredValue);
        return (
            <div>
            <Row>
                <Col>
                    <QuoteLogEntry hideButton={true} visible={this.state.visible} selectedQuoteLog={this.state.selectedQuoteLog} onVisibleChange={this.handleEntryVisibleChange} dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} type={this.state.reportFilter} />
                </Col>
            </Row>
            <Row style={{paddingTop: 20}}>
                <Col span={24}>
                    <Title level={4}>AGENT ACTIVITY LOG</Title>
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{textAlign: "center"}}> 
                    {
                        this.props.isQuoteLogLoading ?
                        <div style={{textAlign: 'center'}}> <Spin></Spin><p><Title level={3}>Loading Agent Activity Log</Title></p></div>:
                        <div>
                            <Table onChange={handleChange} dataSource={onlySelf == false ? filteredQuoteLog : filteredQuoteLog.filter(a => a.agent == loggedInAgentName)} columns={filteredColumns} pagination={{ pageSize: 50 }}  />
                        </div>
                    }
                    
                </Col>
            </Row>
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(QuoteLog);